import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';
import useDidMountEffect from 'hooks/useDidMountEffect';

import RecruitHead from 'components/Recruit/RecruitHead';

gsap.registerPlugin(ScrollTrigger);

const RecruitTalent = () => {
  const { t, i18n } = useTranslation();
  const DelayRef = useRef([]);
  const DelayUpRef = useRef([]);
  const FadeLeftRef = useRef([]);
  const FadeRightRef = useRef([]);
  const ScaleRef = useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const [isEng, setIsEng] = useState(false);

  const lang = {
    ko: false,
    en: true,
    cn: false
  };

  useDidMountEffect(() => {
    setIsEng(lang[i18n.language]);
  }, [i18n.language]);

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.talent_sec',
        start: 'top 20%',
      },
    });

    // 인재상
    tl.fromTo(
      ScaleRef.current,
      {
        autoAlpha: 0,
        scale: 0,
        rotate: 360,
        opacity: 0,
      },
      {
        duration: 1,
        autoAlpha: 1,
        opacity: 1,
        rotate: 0,
        scale: 1,
      }
    );

    // FadeLeftRef 애니메이션
    tl.fromTo(
      FadeLeftRef.current,
      {
        autoAlpha: 0,
        opacity: 0,
        x: -100,
      },
      {
        duration: 1,
        autoAlpha: 1,
        opacity: 1,
        x: 0,
        stagger: 0.3,
      },
      '-=0.25'
    );

    // FadeRightRef 애니메이션
    tl.fromTo(
      FadeRightRef.current,
      {
        autoAlpha: 0,
        opacity: 0,
        x: 100,
      },
      {
        duration: 1,
        autoAlpha: 1,
        opacity: 1,
        x: 0,
        stagger: 0.3,
      },
      '-=0.25'
    );

    // 채용 절차
    DelayRef.current.forEach((el, index) => {
      gsap.fromTo(
        el, {
          autoAlpha: 0,
          opacity: 0,
          x: -100,
        },
        {
          duration: 1,
          autoAlpha: 1,
          opacity: 1,
          x: 0,
          delay: index * 0.2, // 딜레이 추가
          scrollTrigger: {
            trigger: el,
            start: 'top 90%',
            toggleActions: 'restart none none reverse',
          },
        }
      )
    });

    // 채용 절차
    DelayUpRef.current.forEach((el, index) => {
      gsap.fromTo(
        el, {
          autoAlpha: 0,
          opacity: 0,
          y: 100,
        },
        {
          duration: 1,
          autoAlpha: 1,
          opacity: 1,
          y: 0,
          delay: index * 0.1, // 딜레이 추가
          scrollTrigger: {
            trigger: el,
            start: 'top bottom',
            toggleActions: 'restart none none reverse',
          },
        }
      )
    });
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const addToRefs = (el, refArray) => {
    if (el && !refArray.current.includes(el)) {
      refArray.current.push(el);
    }
  };

  const talentItem = [
    {
      title: 'Innovation',
      ic: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/talent_ic01.svg',
      desc: t('recruit.talent.img.first'),
      pc: 'left_top',
      mo: '',
      align: 'right',
      color: '',
    },
    {
      title: 'Expertise',
      ic: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/talent_ic02.svg',
      desc: t('recruit.talent.img.second'),
      pc: 'right_top',
      mo: 'right',
      align: '',
      color: 'blue'
    },
    {
      title: 'Creative',
      ic: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/talent_ic04.svg',
      desc: t('recruit.talent.img.third'),
      pc: 'right_bottom',
      mo: '',
      align: '',
      color: ''
    },
    {
      title: 'Leadership',
      ic: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/talent_ic03.svg',
      desc: t('recruit.talent.img.fourth'),
      pc: 'left_bottom',
      mo: 'right',
      align: 'right',
      color: 'blue'
    },
  ];
  const recruitItem = [
    {
      title: t('recruit.talent.icon.first'),
      ic: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/talent_ic05.svg'
    },
    {
      title: t('recruit.talent.icon.second'),
      ic: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/talent_ic06.svg'
    },
    {
      title: t('recruit.talent.icon.third'),
      ic: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/talent_ic07.svg'
    },
    {
      title: t('recruit.talent.icon.fourth'),
      ic: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/talent_ic08.svg'
    }
  ];

  return (
    <React.Fragment>
      {/* 루트별 공통 비주얼 헤더 */}
      <RecruitHead/>

      {/* 컨텐츠 */}
      <Wrap>
        {/* 인재상 */}
        <section className='talent_sec'>
          <div className='sec_inner'>
            <div className='page_tit'>
              <h4>{t('recruit.talent.title.first')}</h4>
              <p>{t('recruit.talent.sub.first')}</p>
            </div>
            {!isMobile &&
              <div className={isEng ? 'eng talent_box' : 'talent_box'}>
                {talentItem.map((item) => (
                  <div key={item.title} className={`${item.pc} talent_opt`} ref={el => addToRefs(el, FadeLeftRef)}>
                    <div className={`${item.color} icBox`}>
                      <TalentIc src={item.ic}/>
                    </div>
                    <div className={`${item.align} talent_desc`}>
                      <strong>{item.title}</strong>
                      <span>{item.desc}</span>
                    </div>
                  </div>
                ))}
                <img ref={ScaleRef} src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/talent_01.webp' alt='인재상 '/>
              </div>
            }

            {isMobile &&
              <div className={isEng ? 'eng talent_box' : 'talent_box'}>
                {talentItem.map((item) => (
                  <div key={item.title} className={`${item.mo} talent_opt`} ref={el => addToRefs(el, DelayUpRef)}>
                    <div className={`${item.color} icBox`}>
                      <TalentIc src={item.ic}/>
                    </div>
                    <div className='talent_desc'>
                      <strong>{item.title}</strong>
                      <span>{item.desc}</span>
                    </div>
                  </div>
                ))}
              </div>
            }
          </div>
        </section>
        {/* 채용 절차 */}
        <section className='recruit_sec'>
          <div className='sec_inner'>
            <div className='page_tit'>
              <h4>{t('recruit.talent.title.second')}</h4>
              <p>{t('recruit.talent.sub.second')}</p>
            </div>
            <div className='recruit_step'>
              {!isMobile &&
                <>
                  <ul className='step_info'>
                    {recruitItem.map((item) => (
                      <li key={item.title} ref={el => addToRefs(el, DelayRef)}>
                        <div className='step_in'>
                          <span>{item.title}</span>
                          <div className='ic_box'>
                            <div className='step_ic'>
                              <StepIc src={item.ic}/>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <ul className='step_desc' ref={el => addToRefs(el, DelayRef)}>
                    <li>{t('recruit.talent.step.first')}</li>
                    <li>{t('recruit.talent.step.second')}</li>
                    <li>{t('recruit.talent.step.third')} / <a href='mailto:recruit@ust.co'>recruit@ust.co</a> / <a href='tel:031-786-1027'>031-786-1027</a></li>
                  </ul>
                </>
              }

              {isMobile &&
                <>
                  <ul className='step_info'>
                    {recruitItem.map((item) => (
                      <li key={item.title} ref={el => addToRefs(el, DelayRef)}>
                        <div className='step_in'>
                          <span>{item.title}</span>
                          <div className='ic_box'>
                            <div className='step_ic'>
                              <StepIc src={item.ic}/>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <ul className='step_desc' ref={el => addToRefs(el, DelayUpRef)}>
                    <li>{t('recruit.talent.step.first')}</li>
                    <li>{t('recruit.talent.step.second')}</li>
                    <li>{t('recruit.talent.step.third')} / <a href='mailto:recruit@ust.co'>recruit@ust.co</a> / <a href='tel:031-786-1027'>031-786-1027</a></li>
                  </ul>
                </>
              }
            </div>
          </div>
        </section>
      </Wrap>
    </React.Fragment>
  );
};

const Wrap = styled.div`
  position: relative;

  // 인재상
  & .talent_sec {
    position: relative;
    padding: 140px 0 125px;

    & .talent_box {
      margin-top: 80px;
      position: relative;
      text-align: center;

      &.eng {
        .talent_desc {
          span {
            min-height: 72px;
          }
        }
      }

      & > .talent_opt {
        z-index: 3;
        position: absolute;
        display: flex;
        align-items: center;
        gap: 0 30px;
        max-width: 33.33%;

        &.left_top {
          left: 0;
          top: 0;
        }

        &.left_bottom {
          left: 0;
          bottom: 0;
        }

        &.right_top {
          right: 0;
          top: 0;
          flex-direction: row-reverse;
        }

        &.right_bottom {
          right: 0;
          bottom: 0;
          flex-direction: row-reverse;
        }

        & .talent_desc {
          text-align: right;

          strong {
            font-weight: 700;
            font-size: var(--font-nav);
            color: var(--color-grey-100);
          }

          span {
            margin-top: 10px;
            display: block;
            font-weight: 500;
            font-size: var(--font-nav-tit24);
            color: var(--color-grey-80);
          }

          &.right {
            text-align: left;
          }
        }

        & .icBox {
          min-width: 127px;
          width: 127px;
          height: 127px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--color-grey-100);

          &.blue {
            background: var(--primary-color);
          }
        }
      }

      & img {
        max-width: 100%;
      }
    }
  }

  // 채용절차
  & .recruit_sec {
    position: relative;
    padding: 125px 0 180px;

    & .recruit_step {
      margin-top: 80px;
      position: relative;

      & .step_info {
        padding-bottom: 60px;
        border-bottom: 1px solid var(--color-grey-20);
        display: flex;

        & li {
          position: relative;
          width: 25%;

          & .step_in {
            text-align: center;

            & span {
              display: block;
              font-weight: 400;
              font-size: var(--font-nav-tit24);
              color: var(--color-grey-80);
            }

            & .ic_box {
              margin-top: 25px;
              position: relative;
              width: 100%;

              & .step_ic {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 200px;
                height: 200px;
                border-radius: 50%;
                background: var(--color-grey-5);
              }
            }
          }

          & + li {
            & .ic_box::before {
              content: '';
              position: absolute;
              top: 50%;
              left: -27.5px;
              transform: translateY(-50%);
              width: 55px;
              height: 55px;
              background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/recruit_arrow.svg') no-repeat;
              background-size: 55px 55px;
            }
          }
        }
      }
    }

    & .step_desc {
      padding-top: 60px;

      li, a {
        font-weight: 400;
        font-size: var(--font-nav-tit);
        color: var(--color-grey-50);

        & + li {
          margin-top: 12px;
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    // 인재상
    & .talent_sec {
      padding: 70px 0 50px;

      & .talent_box {
        margin-top: 60px;

        & > .talent_opt + .talent_opt {
          margin-top: 20px;
        }

        & > .talent_opt {
          z-index: 3;
          position: relative;
          display: flex;
          align-items: center;
          gap: 0 30px;
          max-width: none;
          width: 100%;

          &.right {
            flex-direction: row-reverse;
            text-align: right;

            & .talent_desc {
              text-align: right;
            }
          }

          & .talent_desc {
            text-align: left;

            strong {
              font-weight: 700;
              font-size: var(--font-nav);
              color: var(--color-grey-100);
            }

            span {
              margin-top: 10px;
              display: block;
              font-weight: 500;
              font-size: var(--font-nav-tit24);
              color: var(--color-grey-80);
            }
          }

          & .icBox {
            min-width: 127px;
            width: 127px;
            height: 127px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--color-grey-100);

            &.blue {
              background: var(--primary-color);
            }
          }
        }

        & img {
          max-width: 100%;
        }
      }
    }

    // 채용절차
    & .recruit_sec {
      padding: 50px 0 90px;

      & .recruit_step {
        margin-top: 60px;

        & .step_info {
          padding-bottom: 60px;
          gap: 85px 0;
          flex-direction: column;

          & li {
            position: relative;
            width: 100%;

            & .step_in {
              text-align: center;

              & span {
                display: block;
                font-weight: 400;
                font-size: var(--font-nav-tit24);
                color: var(--color-grey-80);
              }

              & .ic_box {
                margin-top: 25px;
                position: relative;
                width: 100%;

                & .step_ic {
                  position: relative;
                  width: 33.33%;
                  height: auto;

                  &::before {
                    content: '';
                    display: block;
                    padding-top: 100%;
                  }

                  & img {
                    width: 50%;
                  }
                }
              }
            }

            & + li {
              & .ic_box::before {
                top: -105px;
                left: 50%;
                transform: translateX(-50%) rotate(90deg);
              }
            }
          }
        }
      }

      & .step_desc {
        padding-top: 20px;
      }
    }
  }
    
  @media only screen and (max-width: 540px) {
    & .talent_sec {
      padding: 35px 0 25px;

      & .talent_box {
        margin-top: 30px;

        & > .talent_opt + .talent_opt {
          margin-top: 15px;
        }

        & > .talent_opt {
          gap: 0 15px;


          & .icBox {
            min-width: 77px;
            width: 77px;
            height: 77px;

            img {
              width: 50%;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 360px) {
    & .talent_sec {

      & .talent_box {

        & > .talent_opt {

          & .icBox {
            min-width: 55px;
            width: 55px;
            height: 55px;

            img {
              width: 50%;
            }
          }
        }
      }
    }
  }
`;

const TalentIc = styled.img.attrs({ alt: '인재상 아이콘' })``;
const StepIc = styled.img.attrs({ alt: '채용절차 아이콘' })``;

export default RecruitTalent;