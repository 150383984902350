import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDidMountEffect from 'hooks/useDidMountEffect';

// JS
import * as Server from 'assets/js/Server';
import * as Common from 'assets/js/Common';

const Catalogue = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const [search, setSearch] = useState({
    language: '',
  });
  const [catalogue, setCatalogue] = useState({
    seq: 0,
    catalogueUrl: '',
    language: '',
    originName: '',
  });

  const lang = {
    ko: 'KOR',
    en: 'ENG',
    cn: 'CHN'
  };

  useEffect(() => {
    setSearch({
      language: lang[i18n.language]
    });

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useDidMountEffect(() => {
    setSearch({
      language: lang[i18n.language]
    });
  }, [i18n.language]);

  useDidMountEffect(() => {
    if (!Common.isEmpty(search.language)) {
      callCatalogue();
    }
  }, [search]);

  const callCatalogue = () => {
    Server.sendGet('ustKr/catalogue', {
      language: search.language,
    }, getCatalogue).then();
  };
  const getCatalogue = res => {
    setCatalogue(res['resultObject']);
  };

  return (
    <>
      <a href={catalogue.catalogueUrl} rel='noreferrer' target={'_blank'}>
        {t('solution.catalogue')}
      </a>
    </>
  );
};

export default Catalogue;