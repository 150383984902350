import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

import Header from 'layout/Header';
import Footer from 'layout/Footer';

const ContentLayout = () => {
  const [scrollMove, setScrollMove] = useState(null);

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    window.onscroll = () => {
      const currentScrollPos = window.pageYOffset;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      // 스크롤이 맨 위에 있을 때는 헤더를 보여줌
      if (scrollTop === 0) {
        setScrollMove(null);
      } else if (currentScrollPos > prevScrollPos) {
        setScrollMove(false); // 스크롤 다운
      } else if (scrollTop < 90) {
        setScrollMove(null);
      } else {
        setScrollMove(true); // 스크롤 업
      }

      prevScrollPos = currentScrollPos;
    };

    return () => (window.onscroll = null);
  }, []);

  return (
    <>
      <ContentWrap>
        <Header $Down={scrollMove}/>
        <Outlet/>
        <Footer/>
      </ContentWrap>
    </>
  );
};

const ContentWrap = styled.div`
  position: relative;
`;

export default ContentLayout;