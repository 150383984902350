import React, { useRef, useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';
import useDidMountEffect from 'hooks/useDidMountEffect';

import RecruitHead from 'components/Recruit/RecruitHead';

gsap.registerPlugin(ScrollTrigger);

const RecruitBenefit = () => {
  const { t, i18n } = useTranslation();
  const DelayRef = useRef([]);
  const ScaleRef = useRef([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const [isEng, setIsEng] = useState(false);

  const lang = {
    ko: false,
    en: true,
    cn: false
  };

  useDidMountEffect(() => {
    setIsEng(lang[i18n.language]);
  }, [i18n.language]);

  useEffect(() => {
    DelayRef.current.forEach((el, index) => {
      gsap.fromTo(
        el, {
          autoAlpha: 0,
          opacity: 0,
          scale: 0.7,
        },
        {
          duration: 1,
          autoAlpha: 1,
          opacity: 1,
          scale: 1,
          delay: index * 0.1, // 딜레이 추가
          scrollTrigger: {
            trigger: el,
            start: 'top 85%',
            toggleActions: 'restart none none reverse',
          },
        }
      )
    });

    ScaleRef.current.forEach((el, index) => {
      gsap.fromTo(
        el, {
          autoAlpha: 0,
          opacity: 0,
          scale: 0.7,
        },
        {
          duration: 1,
          autoAlpha: 1,
          opacity: 1,
          scale: 1,
          delay: index * 0.1, // 딜레이 추가
          scrollTrigger: {
            trigger: el,
            start: 'top 85%',
            toggleActions: 'restart none none reverse',
          },
        }
      )
    });

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const addToRefs = (el, refArray) => {
    if (el && !refArray.current.includes(el)) {
      refArray.current.push(el);
    }
  };

  const Insurance = [
    { title: t('recruit.benefit.legal.first') },
    { title: t('recruit.benefit.legal.second') },
    { title: t('recruit.benefit.legal.third') },
    { title: t('recruit.benefit.legal.fourth') }
  ];
  const benefitItems = [
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/benefit_ic01.svg',
      label: t('recruit.benefit.extra.first')
    },
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/benefit_ic02.svg',
      label: t('recruit.benefit.extra.second')
    },
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/benefit_ic03.svg',
      label: t('recruit.benefit.extra.third')
    },
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/benefit_ic04.svg',
      label: t('recruit.benefit.extra.fourth')
    },
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/benefit_ic05.svg',
      label: t('recruit.benefit.extra.fifth')
    },
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/benefit_ic06.svg',
      label: t('recruit.benefit.extra.sixth')
    },
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/benefit_ic07.svg',
      label: t('recruit.benefit.extra.seventh')
    },
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/benefit_ic08.svg',
      label: t('recruit.benefit.extra.eighth')
    },
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/benefit_ic09.svg',
      label: t('recruit.benefit.extra.ninth')
    },
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/benefit_ic10.svg',
      label: t('recruit.benefit.extra.tenth')
    },
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/benefit_ic11.svg',
      label: t('recruit.benefit.extra.eleventh')
    },
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/benefit_ic12.svg',
      label: t('recruit.benefit.extra.twelfth')
    },
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/benefit_ic13.svg',
      label: t('recruit.benefit.extra.thirteenth')
    },
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/benefit_ic14.svg',
      label: t('recruit.benefit.extra.fourteenth')
    },
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/benefit_ic15.svg',
      label: t('recruit.benefit.extra.fifteenth')
    },
    {
      img: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/recruit/benefit_ic16.svg',
      label: t('recruit.benefit.extra.sixteenth')
    },
  ];

  const formatData = (text) => {
    return text.split('\n').map((str, index) => (
      <Fragment key={index}>
        {str}
        <br/>
      </Fragment>
    ));
  };

  return (
    <React.Fragment>
      {/* 루트별 공통 비주얼 헤더 */}
      <RecruitHead/>

      {/* 컨텐츠 */}
      <Wrap>
        {/* 법정 복리후생 */}
        <section className='legal_sec'>
          <div className='sec_inner'>
            <div className='page_tit'>
              <h4>{t('recruit.benefit.title.first')}</h4>
              <p>{t('recruit.benefit.sub.first')}</p>
            </div>
            <div className='legal_list'>
              <ul className={isEng ? 'eng' : ''}>
                {Insurance.map((item) => (
                  <li key={item.title} ref={el => addToRefs(el, DelayRef)}>
                    <div className='legal_in'>
                      <span>{item.title}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
        {/* 법정 외 복리후생 */}
        <section className='extra_sec'>
          <div className='sec_inner'>
            <div className='page_tit'>
              <h4>{t('recruit.benefit.title.second')}</h4>
              <p>{formatData(t('recruit.benefit.sub.second'))}</p>
            </div>
            <div className='extra_list'>
              <ul>
                {benefitItems.map((item, index) => (
                  <li key={index}>
                    <div className='extra_in' ref={el => addToRefs(el, ScaleRef)}>
                      <div className='extra_desc'>
                        <div className='extra_ic'>
                          <ExtraIc src={item.img}/>
                        </div>
                        <span>{item.label}</span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </Wrap>
    </React.Fragment>
  );
};

const Wrap = styled.div`
  position: relative;

  & .legal_sec {
    position: relative;
    padding: 140px 0 100px;

    & .legal_list {
      margin-top: 80px;
      position: relative;

      & .eng {
        .legal_in span {
          font-size: 18px;
          text-align: center;
        }
      }

      & ul {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0 40px;

        & li {
          & .legal_in {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 220px;
            height: 220px;
            background: var(--color-grey-5);
            border-radius: 50%;

            & span {
              font-weight: 700;
              font-size: var(--font-history-txt);
              color: var(--color-grey-90);
            }
          }
        }
      }
    }
  }

  & .extra_sec {
    position: relative;
    padding: 100px 0 180px;

    & .extra_list {
      margin-top: 80px;
      position: relative;
      border-top: 1px solid var(--color-grey-70);
      border-bottom: 1px solid var(--color-grey-70);

      & ul {
        display: flex;
        flex-wrap: wrap;

        & li {
          width: 25%;

          .extra_in {
            position: relative;
            padding: 75px 0;
            text-align: center;

            & span {
              margin-top: 30px;
              display: block;
              font-weight: 400;
              font-size: var(--font-history-txt);
              color: var(--color-grey-80);
            }
          }

          & + li:nth-child(n+5) {
            border-top: 1px solid var(--color-grey-30);
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {

    & .legal_sec {
      position: relative;
      padding: 70px 0 50px;

      & .legal_list {
        margin-top: 60px;
        position: relative;

        & .eng {
          .legal_in span {
            font-size: 18px;
          }
        }

        & ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          gap: 24px;

          & li {
            width: calc(50% - 12px);

            & .legal_in {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 75%;
              height: auto;
              margin: 0 auto;

              &::before {
                content: '';
                display: block;
                padding-top: 100%;
              }

              & span {
                font-weight: 700;
                font-size: var(--font-history-txt);
                color: var(--color-grey-90);
              }
            }
          }
        }
      }
    }

    & .extra_sec {
      position: relative;
      padding: 50px 0 90px;

      & .extra_list {
        margin-top: 60px;
        position: relative;
        border: none;

        & ul {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;

          & li {
            width: calc(50% - 10px);

            .extra_in {
              padding: 0;
              width: 100%;
              border: 1px solid var(--color-grey-30);
              border-radius: 5px;

              &::before {
                content: '';
                display: block;
                padding-top: 100%;
              }

              .extra_desc {
                padding: 16px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
              }

              .extra_ic {
                img {
                  width: 50%;
                }
              }

              & span {
                margin-top: 18px;
                display: block;
                font-weight: 400;
                font-size: var(--font-history-txt);
                color: var(--color-grey-80);
              }
            }

            & + li:nth-child(n+5) {
              border: none;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    & .legal_sec {
      position: relative;
      padding: 35px 0 25px;

      & .legal_list {
        margin-top: 30px;
        position: relative;

        & ul {
          & li {
            width: calc(50% - 12px);

            & .legal_in {
              width: 100%;
            }
          }
        }
      }
    }

    & .extra_sec {
      position: relative;
      padding: 25px 0 45px;

      & .extra_list {
        margin-top: 30px;
      }
    }
  }
`;

const ExtraIc = styled.img.attrs({ alt: '복리후생' })``;

export default RecruitBenefit;