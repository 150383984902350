export const TOP_HISTORY = [
  {
    year: '2024',
    months: [
      { month: '03', desc: 'company.history.twentyFour.second', isBlue: true },
      { month: '01', desc: 'company.history.twentyFour.first', isBlue: false }
    ]
  },
  {
    year: '2023',
    months: [
      { month: '07', desc: 'company.history.twentyThree.first', isBlue: false },
      { month: '06', desc: 'company.history.twentyThree.second', isBlue: false },
      { month: '04', desc: 'company.history.twentyThree.third', isBlue: false },
      { month: '03', desc: 'company.history.twentyThree.fourth', isBlue: true }
    ]
  },
  {
    year: '2022',
    months: [
      {
        month: '12',
        descList: [
          'company.history.twentyTwo.first.first',
          'company.history.twentyTwo.first.second',
          'company.history.twentyTwo.first.third'
        ]
      },
      { month: '11', desc: 'company.history.twentyTwo.second', isBlue: false },
      { month: '10', desc: 'company.history.twentyTwo.third', isBlue: false },
      {
        month: '09',
        descList: [
          'company.history.twentyTwo.fourth.first',
          'company.history.twentyTwo.fourth.second'
        ],
        images: [
          'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/history_01.webp',
          'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/history_07.webp'
        ]
      },
      { month: '05', desc: 'company.history.twentyTwo.fifth', isBlue: false },
      {
        month: '01',
        descList: [
          'company.history.twentyTwo.sixth.first',
          'company.history.twentyTwo.sixth.second'
        ]
      }
    ]
  }
];

export const MID_HISTORY = [
  {
    year: '2021',
    months: [
      {
        month: '12',
        descList: [
          'company.history.twentyOne.first.first',
          'company.history.twentyOne.first.second',
          'company.history.twentyOne.first.third',
          'company.history.twentyOne.first.fourth',
        ],
        isBlue: false,
      },
    ]
  },
  {
    year: '2019',
    months: [
      {
        month: '11',
        desc: 'company.history.nineteen.first',
        image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/history_02.webp',
        isBlue: false
      },
    ]
  },
  {
    year: '2015',
    months: [
      {
        month: '04',
        desc: 'company.history.fifteen.first',
        image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/history_03.webp',
        isBlue: false
      },
    ]
  },
  {
    year: '2014',
    months: [
      {
        month: '12',
        desc: 'company.history.fourteen.first',
        isBlue: false
      },
      {
        month: '10',
        desc: 'company.history.fourteen.second',
        image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/history_04.webp',
        isBlue: false
      },
    ]
  },
];

export const BOT_HISTORY = [
  {
    year: '2012',
    months: [
      { month: '09', desc: 'company.history.twelve.first', isBlue: false },
    ]
  },
  {
    year: '2011',
    months: [
      { month: '11', desc: 'company.history.eleven.first', isBlue: false },
    ]
  },
  {
    year: '2009',
    months: [
      { month: '10', desc: 'company.history.nine.first' },
      { month: '03', desc: 'company.history.nine.second', isBlue: false },
    ]
  },
  {
    year: '2008',
    months: [
      {
        month: '11',
        desc: 'company.history.eight.first',
        isBlue: false
      },
    ]
  }
];