import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

/* swiper */
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

gsap.registerPlugin(ScrollTrigger);

const MainHome = () => {
  const { t } = useTranslation();
  // 스크롤 트리거 이벤트
  // 스크롤 FadeUp
  const FadeUpRef = useRef([]);
  FadeUpRef.current = [];
  // 스크롤 FadeLeft
  const FadeLeftRef = useRef([]);
  FadeLeftRef.current = [];

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  // 섹션3 마우스 호버 이벤트
  const [hoverBox, setHoverBox] = useState(null);

  const UpRefs = (el) => {
    if (el && !FadeUpRef.current.includes(el)) {
      FadeUpRef.current.push(el);
    }
  };
  const LeftRefs = (el) => {
    if (el && !FadeLeftRef.current.includes(el)) {
      FadeLeftRef.current.push(el);
    }
  };

  // 1 섹션 - > 2 섹션 스무스 스크롤
  useEffect(() => {
    const fadeUpEls = gsap.utils.toArray(FadeUpRef.current);
    const fadeLeftEls = gsap.utils.toArray(FadeLeftRef.current);

    fadeUpEls.forEach((el, index) => {
      gsap.fromTo(
        el,
        { autoAlpha: 0, y: 100 },
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          scrollTrigger: {
            trigger: el,
            start: 'top bottom',
            toggleActions: 'restart none none reverse',
          },
          delay: index * 0.15, // 딜레이 추가
        }
      );
    });

    fadeLeftEls.forEach((el, index) => {
      gsap.fromTo(
        el,
        { autoAlpha: 0, x: -100 },
        {
          autoAlpha: 1,
          x: 0,
          duration: 1,
          scrollTrigger: {
            trigger: el,
            start: 'top bottom-=100',
            toggleActions: 'restart none none reverse',
          },
          delay: index * 0.15, // 딜레이 추가
        }
      );
    });

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const HoverGasket = (menuItem) => {
    if (!isMobile) {
      setHoverBox(menuItem);
    }
  };
  const HoverOutGasket = () => {
    if (!isMobile) {
      setHoverBox(null);
    }
  };
  const getHoverClass = () => {
    let className = 'gasket_inner';

    if (hoverBox === 'left') {
      className += ' left';
    } else if (hoverBox === 'right') {
      className += ' right';
    }

    return className;
  };

  const [loaded, setLoaded] = useState(false);
  const bgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
        ([entry]) => {
          if(entry.isIntersecting) {
            setLoaded(true);
            observer.disconnect();
          }
        },
        { threshold: 0.1 }
    );

    if (bgRef.current) {
      observer.observe(bgRef.current);
    }

    return () => {
      if (bgRef.current){
        observer.unobserve(bgRef.current);
      }
    };
  }, [])


  return (
    <React.Fragment>
      <Wrap className='_m'>
        {/* 비주얼 영역 (이미지 슬라이드 & 비디오)(Visual) */}
        <section className='visual_section'>
          <video controls='' playsInline muted autoPlay loop>
            <source src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/visual_mv.mov' type='video/mp4'/>
          </video>
          <div className='visual_tit'>
            <h1>World Best IT Sourcing Global Provider</h1>
            <strong>Universal Solution Technology</strong>
          </div>

          <div className='scroll-ic'>
            <p>Scroll</p>
            <i/>
          </div>
        </section>
        {/* 회사 소개 비주얼 레이아웃(Company) */}
        <section className={`company_section ${loaded ? 'loaded' : ''}`} ref={bgRef}>
          <div className='inner'>
            <div className='company_tit'>
              <h1>{t('main.company.title')}</h1>
            </div>
            {!isMobile &&
              <ul className='company_list'>
                <li className='box'>
                  <Link to='/company/outline' aria-label="Company Outline">
                    <div className='img-box' ref={UpRefs}>
                      <LazyLoadImage className='fade-up' src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/company_01.webp' width={345} height={350} alt='outline'/>
                    </div>
                    <div className='txt-box' ref={LeftRefs}>
                      <strong>{t('main.company.outline.title')}</strong>
                      <span>{t('main.company.outline.sub')}</span>
                    </div>
                  </Link>
                </li>
                <li className='box'>
                  <Link to='/company/ceo' aria-label="CEO Greetings">
                    <div className='img-box' ref={UpRefs}>
                      <LazyLoadImage className='fade-up' src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/company_02.webp' width={345} height={550} alt='ceo'/>
                    </div>
                    <div className='txt-box' ref={LeftRefs}>
                      <strong>{t('main.company.ceo.title')}</strong>
                      <span>{t('main.company.ceo.sub')}</span>
                    </div>
                  </Link>
                </li>
                <li className='box'>
                  <Link to='/company/history' aria-label="Company History">
                    <div className='img-box' ref={UpRefs}>
                      <LazyLoadImage className='fade-up' src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/company_03.webp' width={345} height={350} alt='history'/>
                    </div>
                    <div className='txt-box' ref={LeftRefs}>
                      <strong>{t('main.company.history.title')}</strong>
                      <span>{t('main.company.history.sub')}</span>
                    </div>
                  </Link>
                </li>
                <li className='box'>
                  <Link to='/company/business' aria-label="Main Business">
                    <div className='img-box' ref={UpRefs}>
                      <LazyLoadImage className='fade-up' src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/company_04.webp' width={345} height={550} alt='business'/>
                    </div>
                    <div className='txt-box' ref={LeftRefs}>
                      <strong>{t('main.company.business.title')}</strong>
                      <span>Spiral Gasket / Pad Gasket</span>
                    </div>
                  </Link>
                </li>
              </ul>
            }
            {isMobile &&
              <Swiper
                className='company_list'
                spaceBetween={20}
                slidesPerView={1.4}
                loop={true}
                pagination={true}
                modules={[Pagination]}
              >
                <SwiperSlide className='box'>
                  <Link to='/company/outline'>
                    <div className='img-box'>
                      <LazyLoadImage className='fade-up' src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/company_01.webp' alt='outline'/>
                    </div>
                    <div className='txt-box'>
                      <strong>{t('main.company.outline.title')}</strong>
                      <span>{t('main.company.outline.sub')}</span>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide className='box'>
                  <Link to='/company/ceo'>
                    <div className='img-box'>
                      <LazyLoadImage className='fade-up' src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/company_02.webp' alt='ceo'/>
                    </div>
                    <div className='txt-box'>
                      <strong>{t('main.company.ceo.title')}</strong>
                      <span>{t('main.company.ceo.sub')}</span>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide className='box'>
                  <Link to='/company/history'>
                    <div className='img-box'>
                      <LazyLoadImage className='fade-up' src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/company_03.webp' alt='history'/>
                    </div>
                    <div className='txt-box'>
                      <strong>{t('main.company.history.title')}</strong>
                      <span>{t('main.company.history.sub')}</span>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide className='box'>
                  <Link to='/company/business'>
                    <div className='img-box'>
                      <LazyLoadImage className='fade-up' src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/company_04.webp' alt='business'/>
                    </div>
                    <div className='txt-box'>
                      <strong>{t('main.company.business.title')}</strong>
                      <span>Spiral Gasket / Pad Gasket</span>
                    </div>
                  </Link>
                </SwiperSlide>
              </Swiper>
            }
          </div>
        </section>
        {/* 제품 견적 문의 배너(Solution)  */}
        <section className='txt_section'>
          <div className='inner'>
            <div className='txt_desc'>
              <h1 dangerouslySetInnerHTML={{ __html: t('main.solution.title.first') }}></h1>
              <Link to='/support/consult'><span>{t('main.solution.btn')}</span></Link>
            </div>
          </div>
        </section>
        {/* Spiral Gasket & Pad Gasket(Solution) */}
        <section className='gasket_section'>
          <div className={getHoverClass()}>
            <div className='spiral_bg' onMouseEnter={() => HoverGasket('left')} onMouseLeave={HoverOutGasket}>
              <div className='gasket_desc'>
                <h1>Spiral Gasket</h1>
                <ul>
                  <li>{t('main.gasket.spiral.first')}</li>
                  <li>{t('main.gasket.spiral.second')}</li>
                  <li>{t('main.gasket.spiral.third')}</li>
                </ul>
                <Link to='/solution/spiral'>VIEW MORE+</Link>
              </div>
            </div>
            <div className='pad_bg' onMouseEnter={() => HoverGasket('right')} onMouseLeave={HoverOutGasket}>
              <div className='gasket_desc'>
                <h1>Pad Gasket</h1>
                <ul>
                  <li>{t('main.gasket.pad.first')}</li>
                  <li>{t('main.gasket.pad.second')}</li>
                  <li>{t('main.gasket.pad.third')}</li>
                </ul>
                <Link to='/solution/pad'>VIEW MORE+</Link>
              </div>
            </div>
          </div>
        </section>
        {/* 인재 문구 */}
        <section className='txt_section'>
          <div className='inner'>
            <div className='txt_desc'>
              <h1 dangerouslySetInnerHTML={{ __html: t('main.recruit.banner.title') }}></h1>
              <p>{t('main.recruit.banner.sub')}</p>
            </div>
          </div>
        </section>
        {/* 채용 배너(Recruit) */}
        <section className='recruit_section'>
          <div className='inner'>
            <div className='recruit_desc'>
              <h1>{t('main.recruit.title')}</h1>
              <Link to='/recruit/talent'><span>{t('main.recruit.btn')}</span></Link>
            </div>
          </div>
        </section>
      </Wrap>
    </React.Fragment>
  );
};

const Wrap = styled.div`
  position: relative;

  // 비쥬얼 섹션
  & .visual_section {
    z-index: 1;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100vh;
    overflow: hidden;

    & video {
      width: 100%;
    }

    & .visual_tit {
      width: 95%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      & h1 {
        font-weight: 800;
        font-size: var(--font-v-tit);
        color: var(--color-white);
      }

      & strong {
        margin-top: 20px;
        display: block;
        font-weight: 400;
        font-size: var(--font-v-sub-tit);
        color: var(--color-white);
      }
    }

    & .scroll-ic {
      position: absolute;
      left: 50%;
      bottom: 100px;
      transform: translateX(-50%);
      width: max-content;
      text-align: center;

      & P {
        font-weight: 500;
        font-size: 15px;
        color: var(--color-white);
      }

      & i {
        position: relative;
        margin-top: 12px;
        display: inline-block;
        width: 25px;
        height: 40px;
        border-radius: 14px;
        border: 1px solid var(--color-white);

        &::before {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 2px;
          height: 15px;
          background: #ffffff;
          border-radius: 10px;
          animation: move 1.5s infinite;
          transition: var(--transition);

          @keyframes move {
            0% {
              transform: translate(-50%, -75%);
              opacity: 1;
            }

            50% {
              transform: translate(-50%, 0);
              opacity: 0;
            }

            100% {
              transform: translateY(-50%, -75%);
              opacity: 1;
            }
          }
        }
      }
    }
  }

  // 회사 섹션
  & .company_section {
    z-index: 3;
    position: relative;
    background-size: cover;
    padding: 90px 0 130px;
    
    &.loaded {
      background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/company_bg.webp') no-repeat;
    }
    
    &::before {
      z-index:1;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
    }

    .inner {
      z-index:2;
      position:relative;
      max-width: 1440px;
      margin: 0 auto;
      width: 95%;

      .company_tit {
        position: relative;

        h1 {
          font-weight: 700;
          font-size: var(--font-main-tit);
          line-height: 1.2;
          color: var(--color-grey-100);
          white-space: pre-line;
        }
      }

      .company_list {
        margin-top: 80px;
        display: flex;
        align-items: flex-start;
        gap: 0 20px;

        li {
          flex: 1;
          overflow: hidden;

          div + div {
            margin-top: 18px;
          }

          img {
            width: 100%;
            filter: grayscale(1);
            transition: all .3s ease-in-out;

            &:hover {
              filter: none;
            }
          }

          strong {
            font-weight: 700;
            font-size: var(--font-card-tit);
            color: var(--color-grey-100);
          }

          span {
            margin-top: 10px;
            display: block;
            font-weight: 500;
            font-size: var(--font-card-sub-tit);
            color: var(--color-grey-80);
            line-height: 1.5;
          }
        }
      }
    }
  }

  // 가스켓 섹션
  & .gasket_section {
    z-index: 3;
    position: relative;

    & .gasket_inner {
      position: relative;
      display: flex;
      transition: var(--transition);

      &.left {
        & .spiral_bg {
          width: 70%;

          & .gasket_desc {
            transform: scale(1) translate(-50%, -50%);
          }
        }

        & .pad_bg {
          width: 30%;

          & .gasket_desc {
            transform: scale(0.7) translate(-50%, -50%);
            opacity: 0.5;
          }
        }
      }

      &.right {
        & .spiral_bg {
          width: 30%;

          & .gasket_desc {
            transform: scale(0.7) translate(-50%, -50%);
            opacity: 0.5;
          }
        }

        & .pad_bg {
          width: 70%;

          & .gasket_desc {
            transform: scale(1) translate(-50%, -50%);
          }
        }
      }

      & > div {
        position: relative;
        width: 50%;
        height: 990px;
        transition: var(--transition);

        &::before {
          content: '';
          z-index: 1;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
        }

        &.spiral_bg {
          background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/spiral_bg.webp') no-repeat;
          background-size: cover;
        }

        &.pad_bg {
          background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/pad_bg.webp') no-repeat;
          background-size: cover;
        }
      }

      & .gasket_desc {
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 95%;
        transition: var(--transition);

        & h1 {
          padding-bottom: 36px;
          font-weight: 700;
          font-size: var(--font-main-tit);
          color: var(--color-white);
        }

        & ul {
          padding-top: 36px;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 50%;
            height: 1px;
            background: var(--color-white);
          }

          & li {
            font-weight: 500;
            font-size: var(--font-main-sub-tit);
            line-height: 1.5;
            color: var(--color-white);
          }
        }

        & a {
          margin-top: 36px;
          padding: 0 30px;
          display: inline-block;
          height: 55px;
          line-height: 55px;
          border: 1px solid var(--color-white);
          border-radius: 100px;
          font-weight: 400;
          font-size: var(--font-24);
          color: var(--color-white);
          transition: var(--transition);
        }
      }
    }
  }

  // 채용 섹션
  & .recruit_section {
    z-index: 3;
    position: relative;
    background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/recruit_visual.webp') no-repeat;
    background-size: cover;
    padding: 140px 0 180px;

    & .inner {
      z-index: 2;
      position: relative;
      max-width: 1440px;
      margin: 0 auto;
      width: 95%;

      & .recruit_desc {
        & h1 {
          font-weight: 700;
          font-size: var(--font-main-tit);
          line-height: 1.2;
          color: var(--color-white);
          white-space: pre-line;
        }

        & a {
          margin-top: 70px;
          position: relative;
          display: inline-flex;
          align-items: center;
          height: 50px;
          line-height: 50px;
          padding: 0 30px;
          background: var(--color-clear);
          border: 1px solid var(--color-white);
          border-radius: 100px;
          overflow: hidden;

          span {
            z-index: 2;
            position: relative;
            font-weight: 500;
            font-size: var(--font-btn-18);
            color: var(--color-white);
            transition: var(--transition);
          }

          &::before {
            content: '';
            z-index: 1;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 0;
            height: 100%;
            background: var(--color-white);
            transition: var(--transition);
          }

          &::after {
            z-index: 2;
            position: relative;
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/link_arrow.svg') no-repeat;
            background-size: auto auto;
            transition: var(--transition);
          }

          &:hover {
            span {
              color: var(--color-grey-80);
            }

            &::before {
              width: 100%;
              transform: translateY(-50%) scale(1.5);
            }

            &::after {
              background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/link_arrow_g.svg') no-repeat;
            }
          }
        }
      }
    }
  }

  & .txt_section {
    z-index: 3;
    position: relative;
    padding: 100px 0;
    background: var(--color-white);

    & .inner {
      max-width: 1440px;
      margin: 0 auto;
      width: 95%;

      & .txt_desc {
        text-align: center;

        & h1 {
          font-weight: 700;
          font-size: var(--font-main-tit);
          line-height: 1.2;
          color: var(--color-grey-100);
          white-space: pre-line;

          & em {
            color: var(--primary-color);
          }
        }

        & p {
          display: block;
          margin-top: 20px;
          font-weight: 400;
          font-size: var(--font-page-s);
          color: var(--color-grey-80);
        }

        & a {
          margin-top: 40px;
          z-index: 2;
          position: relative;
          display: inline-flex;
          align-items: center;
          padding: 0 30px;
          height: 50px;
          line-height: 50px;
          border: 1px solid var(--color-grey-80);
          border-radius: 100px;
          overflow: hidden;

          span {
            z-index: 2;
            position: relative;
            font-weight: 500;
            font-size: var(--font-btn-18);
            color: var(--color-grey-80);
            transition: var(--transition);
          }

          &::before {
            z-index: 1;
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 0;
            height: 100%;
            background: var(--color-grey-80);
            transition: var(--transition);
          }

          &::after {
            z-index: 3;
            position: relative;
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background: url(https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/link_arrow_g.svg) no-repeat;
            background-size: 25px 25px;
            transition: var(--transition);
          }

          &:hover {

            span {
              color: var(--color-white);
            }

            &::before {
              width: 100%;
            }

            &::after {
              background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/link_arrow.svg') no-repeat;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {

    // 비쥬얼 섹션
    & .visual_section {
      height: 80vh;

      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: 80vh;
      }

      & .visual_tit {
        width: calc(100% - 32px);
        left: 20px;
        transform: translateY(-50%);
        text-align: left;

        & h1 {
          font-size: var(--font-v-tit);
          color: var(--color-white);
        }

        & strong {
          margin-top: 12px;
          display: block;
          font-weight: 400;
          font-size: var(--font-v-sub-tit);
          color: var(--color-white);
        }
      }

      & .scroll-ic {
        position: absolute;
        left: 50%;
        bottom: 20px;
        transform: translateX(-50%);
        width: max-content;
        text-align: center;

        & P {
          font-weight: 500;
          font-size: 15px;
          color: var(--color-white);
        }

        & i {
          position: relative;
          margin-top: 12px;
          display: inline-block;
          width: 25px;
          height: 40px;
          border-radius: 14px;
          border: 1px solid var(--color-white);

          &::before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 2px;
            height: 15px;
            background: #ffffff;
            border-radius: 10px;
            animation: move 1.5s infinite;
            transition: var(--transition);

            @keyframes move {
              0% {
                transform: translate(-50%, -75%);
                opacity: 1;
              }

              50% {
                transform: translate(-50%, 0);
                opacity: 0;
              }

              100% {
                transform: translateY(-50%, -75%);
                opacity: 1;
              }
            }
          }
        }
      }
    }

    & .company_section {
      padding: 60px 0 100px;

      .inner {
        max-width: 100%;
        width: 100%;
        padding: 0 0 0 20px;

        .company_list {
          margin-top: 40px;
          flex-wrap: wrap;
          gap: 0;

          & .swiper-pagination {
            margin-top: 30px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .swiper-pagination-bullet {
              background: var(--color-grey-40);
              opacity: 1;

              &.swiper-pagination-bullet-active {
                background: var(--primary-color);
              }
            }
          }

          div {
            > a > div + div {
              margin-top: 18px;
            }

            .img-box {
              position: relative;
              overflow: hidden;

              &::before {
                content: '';
                display: block;
                padding-top: 100%;
              }


              img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                width: 100%;
                filter: grayscale(1);
                transition: all .3s ease-in-out;

                &:hover {
                  filter: none;
                }
              }
            }

            strong {
              font-weight: 700;
              font-size: var(--font-card-tit);
              color: var(--color-grey-100);
            }

            span {
              margin-top: 10px;
              display: block;
              font-weight: 500;
              font-size: var(--font-card-sub-tit);
              color: var(--color-grey-80);
              line-height: 1.5;
            }
          }
        }
      }
    }

    & .gasket_section {
      & .gasket_inner {
        flex-wrap: wrap;

        &.left {
          & .spiral_bg {
            width: 70%;

            & .gasket_desc {
              transform: scale(1) translate(-50%, -50%);
            }
          }

          & .pad_bg {
            width: 30%;

            & .gasket_desc {
              transform: scale(0.7) translate(-50%, -50%);
              opacity: 0.5;
            }
          }
        }

        &.right {
          & .spiral_bg {
            width: 30%;

            & .gasket_desc {
              transform: scale(0.7) translate(-50%, -50%);
              opacity: 0.5;
            }
          }

          & .pad_bg {
            width: 70%;

            & .gasket_desc {
              transform: scale(1) translate(-50%, -50%);
            }
          }
        }

        & > div {
          width: 100%;
          height: 405px;

          &::before {
            content: '';
            z-index: 1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
          }

          &.spiral_bg {
            background-position: 50% 50%;
          }

          &.pad_bg {
            background-position: 50% 50%;
          }
        }

        & .gasket_desc {
          z-index: 2;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          width: 95%;
          transition: var(--transition);

          & h1 {
            padding-bottom: 20px;
          }

          & ul {
            padding-top: 20px;

            &::before {
              width: 75%;
            }

            & li {
              font-weight: 500;
            }
          }

          & a {
            margin-top: 30px;
            font-size: var(--font-btn-18);
          }
        }
      }
    }

    & .recruit_section {
      padding: 70px 0 90px;
      background-position: 50% 50%;

      & .inner {
        & .recruit_desc {
          text-align: center;

          & h1 {
          }

          & a {
            margin-top: 35px;

            &::after {
            }
          }
        }
      }
    }

    & .txt_section {
      padding: 70px 0;

      & .inner {
        max-width: 100%;
        width: 100%;
        padding: 0 20px;

        & .txt_desc {

          & p {
            margin-top: 15px;
          }

          & a {
            margin-top: 30px;

          }
        }
      }
    }
  }

  @media only screen and (max-width: 540px) {

    & .visual_section {
      height: 75vh;

      video {
        height: 75vh;
      }
    }

    & .company_section {
      padding: 45px 0 65px;
    }

    & .gasket_section {
      & .gasket_inner {
        & > div {
          height: 325px;
        }

        & .gasket_desc {
          & a {
            margin-top: 20px;
            padding: 0 16px;
            height: 40px;
            line-height: 40px;
          }
        }
      }
    }

    & .recruit_section {
      padding: 35px 0 45px;

      & .inner {
        & .recruit_desc {
          & a {
            margin-top: 25px;
            height: 40px;
            line-height: 40px;
            padding: 0 16px;
          }
        }
      }
    }

    & .txt_section {
      padding: 35px 0;

      & .inner {
        & .txt_desc {
          & a {
            margin-top: 20px;
            padding: 0 16px;
            height: 40px;
            line-height: 40px;
          }
        }
      }
    }
  }
`;

export default MainHome;