import axios from 'axios';

import * as Common from 'assets/js/Common';

const hostUrl = 'https://api.ust.co/';
//const hostUrl = 'http://192.168.0.125:8091/';

export async function sendPost(url, param, cbSuccess) {
  await axios.post(hostUrl + url, param).then(res => {
    console.log('SUCCESS');
    cbSuccess(res.data);
  }).catch(res => {
    if (!Common.isEmpty(res.response)) {
      console.log('ERROR');
    }
  });
}

export async function sendGet(url, param, cbSuccess) {
  url = `${hostUrl + url}?`;

  for (let i = 0; i < Object.keys(param).length; i++) {
    url += `${Object.keys(param)[i]}=${Object.values(param)[i]}&`;
  }

  await axios.get(url.slice(0, url.length - 1)).then(res => {
    console.log('SUCCESS');
    cbSuccess(res.data);
  }).catch(res => {
    if (!Common.isEmpty(res.response)) {
      console.log('ERROR');
    }
  });
}

export async function sendS3Upload(param) {
  return await axios.post(`${hostUrl}file/ustKr/consult/s3upload`, param, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
