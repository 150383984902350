import React, { useState, useRef, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';

import CompanyHead from 'components/Company/CompanyHead';

gsap.registerPlugin(ScrollTrigger);

const CompanyBusiness = () => {
  const { t } = useTranslation();
  const FadeUpRef = useRef([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);

  useEffect(() => {
    FadeUpRef.current.forEach((el) => {
      gsap.fromTo(
        el, {
          autoAlpha: 0,
          opacity: 0,
          y: 100,
        },
        {
          duration: 1,
          autoAlpha: 1,
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: el,
            start: 'top 80%',
            toggleActions: 'restart none none reverse',
          },
        }
      )
    })

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const addToRefs = (el, refArray) => {
    if (el && !refArray.current.includes(el)) {
      refArray.current.push(el);
    }
  };

  const productInfo = [
    {
      no: '01',
      image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/business_01.webp',
      title: 'Emi Spiral Gasket',
      desc1: t('company.business.spiral.first'),
      desc2: t('company.business.spiral.second'),
      desc3: t('company.business.spiral.third'),
      align: ''
    },
    {
      no: '02',
      image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/business_02.webp',
      title: 'Pad Gasket',
      desc1: t('company.business.pad.first'),
      desc2: t('company.business.pad.second'),
      desc3: t('company.business.pad.third'),
      align: 'reverse'
    },
    {
      no: '03',
      image: 'https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/business_03.webp',
      title: 'Equipment Modification',
      desc1: t('company.business.equipment.first'),
      desc2: t('company.business.equipment.second'),
      align: ''
    }
  ];
  const smartFactory = [
    { no: '01', color: 'light', desc: t('company.business.smartFactory.step.first') },
    { no: '02', color: 'primary', desc: t('company.business.smartFactory.step.second') },
    { no: '03', color: 'grey', desc: t('company.business.smartFactory.step.third') },
  ];

  const formatData = (text) => {
    return text.split('\n').map((str, index) => (
      <Fragment key={index}>
        {str}
        <br/>
      </Fragment>
    ));
  }

  return (
    <React.Fragment>
      {/* 루트별 공통 비주얼 헤더 */}
      <CompanyHead/>

      {/* 컨텐츠 */}
      <Wrap>
        {/* 주요 사업 */}
        <section className='business_sec'>
          <div className='sec_inner'>
            <div className='page_tit' ref={el => addToRefs(el, FadeUpRef)}>
              <h4>{t('company.business.title')}</h4>
              <p>{t('company.business.sub')}</p>
            </div>

            <div className='business_list'>
              <ul>
                {productInfo.map((item) => (
                  <li key={item.title}>
                    <div className={`${item.align} list_in`}>
                      <div className='list_img' ref={el => addToRefs(el, FadeUpRef)}>
                        <BusinessImg src={item.image}/>
                      </div>
                      <div className='list_desc' ref={el => addToRefs(el, FadeUpRef)}>
                        <span>{item.no}</span>
                        <strong>{item.title}</strong>
                        <ul>
                          <li>{item.desc1}</li>
                          <li>{item.desc2}</li>
                          {item.desc3 && <li>{item.desc3}</li>}
                        </ul>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
        {/* 스마트 팩토리 */}
        <section className='sf_sec'>
          <div className='sec_inner'>
            <div className='page_tit' ref={el => addToRefs(el, FadeUpRef)}>
              <h4>{t('company.business.smartFactory.first')}</h4>
              <p>{formatData(t('company.business.smartFactory.second'))}</p>
            </div>
            <div className='sf_con'>
              <div className='sf_img'>
                <img ref={el => addToRefs(el, FadeUpRef)} src={t('company.business.smartFactory.img.first')} alt='스마트 팩토리'/>
              </div>
              <div className='sf_desc' ref={el => addToRefs(el, FadeUpRef)}>
                <ul>
                  {smartFactory.map((item) => (
                    <li key={item.no}>
                      <div className={`${item.color} desc_num`}>
                        <strong>{item.no}</strong>
                      </div>
                    </li>
                  ))}
                </ul>
                <ul>
                  {smartFactory.map((item) => (
                    <li key={item.no}>
                      <div className='desc_info'>
                        <span>{item.desc}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </Wrap>
    </React.Fragment>
  );
};

const Wrap = styled.div`
  position: relative;
  // 개요 Content

  // 비즈니스
  & .business_sec {
    position: relative;
    padding: 150px 0 100px;

    & .business_list {
      margin-top: 80px;
      position: relative;

      & > ul {
        position: relative;

        & li {
          .list_in {
            display: flex;
            align-items: center;

            &.reverse {
              flex-direction: row-reverse;

              .list_desc {
                text-align: right;
              }
            }

            > div {
              width: 50%;

              &.list_img img {
                width: 100%;
              }

              &.list_desc {
                padding: 0 40px;
                text-align: left;

                & span {
                  font-weight: 800;
                  font-size: var(--font-history-txt);
                  color: var(--primary-color);
                }

                & strong {
                  margin-top: 15px;
                  display: block;
                  font-weight: 900;
                  font-size: var(--font-history-tit);
                  line-height: 1.2;
                  color: var(--color-grey-90);
                }

                & > ul {
                  margin-top: 40px;

                  & > li {
                    font-weight: 400;
                    font-size: var(--font-history-txt);
                    line-height: 1.5;
                    color: var(--color-grey-80);

                    & + li {
                      margin-top: 10px;
                    }
                  }
                }
              }
            }
          }
        }

        & > li + li {
          margin-top: 80px;
        }
      }
    }
  }

  // 스마트팩토리
  & .sf_sec {
    position: relative;
    padding: 100px 0 170px;

    & .sf_con {
      margin-top: 80px;
      text-align: center;

      & .sf_img img {
        width: auto;
      }

      & .sf_desc {
        margin-top: 80px;

        ul {
          position: relative;
          display: flex;
          align-items: center;

          &:first-child::before {
            content: '';
            z-index: 1;
            position: absolute;
            width: 100%;
            height: 35px;
            border-radius: 100px;
            background: var(--color-grey-10);
          }

          &:last-child {
            align-items: flex-start;

          }

          li {
            z-index: 2;
            position: relative;
            width: 33.33%;

            .desc_num {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              width: 80px;
              height: 80px;
              border-radius: 50%;

              &.light {
                background: var(--primary-sub-color);
              }

              &.primary {
                background: var(--primary-color);
              }

              &.grey {
                background: var(--color-grey-80);
              }

              strong {
                font-weight: 700;
                font-size: var(--font-nav);
                color: var(--color-white);
              }
            }

            .desc_info {
              width: 75%;
              margin: 0 auto;

              span {
                font-weight: 400;
                font-size: var(--font-main-sub-tit);
                line-height: 1.5;
                color: var(--color-grey-80);
              }
            }
          }
        }

        ul + ul {
          margin-top: 20px;
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    // 비즈니스
    & .business_sec {
      position: relative;
      padding: 70px 0 0;

      & .business_list {
        margin-top: 60px;

        & > ul {
          position: relative;

          & li .list_in {
            flex-direction: column;

            &.reverse {
              flex-direction: column;

              .list_desc {
                text-align: left;
              }
            }

            & > div {
              width: 100%;

              &.list_img img {
                width: 100%;
              }

              &.list_desc {
                padding: 20px 16px 60px;
                text-align: left;

                &.left {
                  text-align: left;
                }

                & strong {
                  margin-top: 10px;
                }

                & > ul {
                  margin-top: 20px;
                }
              }
            }
          }

          & > li + li {
            margin-top: 0;
          }
        }
      }
    }

    // 스마트팩토리
    & .sf_sec {
      position: relative;
      padding: 60px 0 90px;

      & .sf_con {
        margin-top: 40px;

        & .sf_img img {
          width: 75%;
        }

        & .sf_desc {
          margin-top: 60px;
          display: flex;
          gap: 0 45px;
          justify-content: center;

          ul {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 32px 0;
            align-items: center;

            &:first-child::before {
              content: none;
            }

            &:last-child {
              align-items: flex-start;
            }

            li {
              z-index: 2;
              position: relative;
              width: 100%;
              height: 80px;
              text-align: left;
              display: flex;
              align-items: center;

              .desc_info {
                width: 100%;
                margin: 0;
              }
            }
          }

          ul + ul {
            margin-top: 0;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 540px) {
    //비즈니스
    & .business_sec {
      position: relative;
      padding: 35px 0 0;

      & .business_list {
        margin-top: 20px;
      }
    }

    // 스마트팩토리
    & .sf_sec {
      padding: 30px 0 45px;

      & .sf_con {
        & .sf_desc {
          gap: 0 15px;

          ul {
            li {
              height: 45px;

              .desc_num {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 45px;
                height: 45px;
                border-radius: 50%;

                strong {
                  font-weight: 700;
                  font-size: var(--font-nav);
                  color: var(--color-white);
                }
              }

              .desc_info {
                width: 100%;
                margin: 0;

                span {
                  font-weight: 400;
                  font-size: var(--font-main-sub-tit);
                  line-height: 1.5;
                  color: var(--color-grey-80);
                }
              }
            }
          }
        }
      }
    }
  }
`;

const BusinessImg = styled.img.attrs({ alt: '주요 사업' })``;

export default CompanyBusiness;