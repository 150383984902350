import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

// 레이아웃 컴포넌트
import Header from 'layout/Header';
import Footer from 'layout/Footer';

const MainLayout = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      setScrolled(scrollTop > 90);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <ContentWrap>
        <Header $Scroll={scrolled}/>
        <Outlet/>
        <Footer/>
      </ContentWrap>
    </>
  );
};

const ContentWrap = styled.div`
  position: relative;
`;

export default MainLayout;