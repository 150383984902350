import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const FooterLayout = () => {
  const { t } = useTranslation();

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <React.Fragment>
      <FooterWrap>
        <div className='inner'>
          <div className='first-menu'>
            <div className='foot_logo'>
              <img src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/bottom_logo.svg' width={102} height={44} alt='Ust logo'/>
            </div>
            <div className='top_btn'>
              <button onClick={scrollTop}>
                <img src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/main/top_btn.svg' width={50} height={50} alt='Go Top'/>
              </button>
            </div>
          </div>
          <div className='second-menu'>
            <div className='foot_desc'>
              <ul>
                <li>{t('footer.info')}</li>
                <li>{t('footer.address')} | {t('footer.businessNum')}</li>
              </ul>
              <ul>
                <li>TEL <a href='tel:031-786-1027'>031-786-1027</a> | FAX 031-378-6694</li>
              </ul>
            </div>
            <div className='foot_copy'>
              <p>&copy; 2023. UST inc. all rights reserved.</p>
            </div>
          </div>
        </div>
      </FooterWrap>
    </React.Fragment>
  );
};

const FooterWrap = styled.div`
  z-index: 3;
  position: relative;
  padding: 50px 0 75px;
  background: #ffffff;
  border-top: 1px solid #CECECE;

  & .inner {
    max-width: 1440px;
    margin: 0 auto;
    width: 95%;

    & .first-menu {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    & .second-menu {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      & ul + ul {
        margin-top: 15px;
      }

      & ul li, & a {
        font-weight: 400;
        font-size: var(--font-page-s);
        line-height: 1.5;
        color: var(--color-grey-70);
      }

      & .foot_copy p {
        font-weight: 400;
        font-size: var(--font-page-s);
        color: var(--color-grey-70);
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    & .inner {

      & .first-menu {
        .top_btn {
          position: fixed;
          bottom: 20px;
          right: 20px;
        }
      }

      & .second-menu {
        margin-top: 30px;
        display: block;

        .foot_copy {
          margin-top: 35px;
          text-align: center;
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 25px 0 40px;
  }
`;

export default FooterLayout;