import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useDidMountEffect from 'hooks/useDidMountEffect';

/* swiper */
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'

const SupportHead = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const [activeLink, setActiveLink] = useState('');

  const noticeRegex = /\/support\/notice/;
  const consultRegex = /\/support\/consult/;

  useEffect(() => {
    if (noticeRegex.test(location.pathname)) {
      setActiveLink(t('header.support.notice'));
    } else if (consultRegex.test(location.pathname)) {
      setActiveLink(t('header.support.consult'));
    }
  }, [i18n.language, location.pathname]);

  useDidMountEffect(() => {
    if (noticeRegex.test(location.pathname)) {
      setActiveLink(t('header.support.notice'));
    } else if (consultRegex.test(location.pathname)) {
      setActiveLink(t('header.support.consult'));
    }
  }, [i18n.language]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <React.Fragment>
      <HeadWrap>
        {/* 비주얼 & 타이틀 */}
        <div className='head_visual'>
          <h2>{activeLink}</h2>
        </div>
        {/* 서브 Nav */}
        <div className='head_sub_nav'>
          <div className='inner'>
            <Swiper className='sub_nav_list' slidesPerView={2}>
              <SwiperSlide className='sub_nav_item'>
                <NavLink to='/support/notice/list' className={`${noticeRegex.test(location.pathname) ? `active` : ``}`}>
                  {t('header.support.notice')}
                </NavLink>
              </SwiperSlide>
              <SwiperSlide className='sub_nav_item'>
                <NavLink to='/support/consult' className={`${consultRegex.test(location.pathname) ? `active` : ``}`}>
                  {t('header.support.consult')}
                </NavLink>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </HeadWrap>
    </React.Fragment>
  );
};

const HeadWrap = styled.div`
  position: relative;

  // 페이지 비주얼 헤더
  & .head_visual {
    position: relative;
    width: 100%;
    height: 450px;
    background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/layout/support_top.webp') no-repeat;
    background-size: cover;

    & h2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: calc(100% - 16px);
      font-weight: 800;
      font-size: var(--font-page-b);
      color: var(--color-white);
    }
  }

  // 페이지 서브 Nav
  & .head_sub_nav {
    position: relative;
    width: 100%;
    border-bottom: 1px solid var(--color-grey-20);

    & .inner {
      max-width: 1440px;
      margin: 0 auto;

      & .sub_nav_list {
        display: flex;
        align-items: center;

        & .sub_nav_item {
          flex: 1 1 0;
          text-align: center;

          & a {
            padding: 30px 0;
            display: block;
            font-weight: 400;
            font-size: var(--font-page-s);
            color: var(--color-grey-100);

            &.active {
              font-weight: 700;
              border-bottom: 3px solid var(--primary-color);
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {

    & .head_visual {
      height: 300px;
    }

    // Sub Nav
    & .head_sub_nav {
      & .inner {
        max-width: none;
        margin: 0;

        & .sub_nav_list {
          & .sub_nav_item {
            flex: none;

            & a {
              padding: 18px 0;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {

    & .head_visual {
      height: 200px;
    }
  }
`;

export default SupportHead;