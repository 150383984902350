import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';

import CompanyHead from 'components/Company/CompanyHead';
import { TOP_HISTORY, MID_HISTORY, BOT_HISTORY } from 'components/Company/HistoryData';

gsap.registerPlugin(ScrollTrigger);

const CompanyHistory = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);

  const { t } = useTranslation();

  const PinRef = useRef([]);
  const ScrollWrapRef = useRef([]);
  const ScrollItemRef = useRef([]);
  const FadeItemRef = useRef([]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const PinBoxes = PinRef.current;
      const ScrollWraps = ScrollWrapRef.current;
      const ScrollItems = ScrollItemRef.current;
      const FadeItems = FadeItemRef.current;

      const triggers = [];

      ScrollWraps.forEach((ScrollWrap, index) => {
        const PinBox = PinBoxes[index];
        const ScrollItem = ScrollItems[index];

        const trigger = ScrollTrigger.create({
          trigger: ScrollWrap,
          pin: isMobile ? null : PinBox,
          pinSpacing: false,
          start: 'top top',
          endTrigger: isMobile ? null : ScrollItem,
          end: isMobile ? null : 'bottom bottom',
        });

        triggers.push(trigger);

        FadeItems.forEach((DescList) => {
          gsap.fromTo(
            DescList,
            {
              opacity: 0,
              y: 100,
            },
            {
              scrollTrigger: {
                trigger: DescList,
                start: 'top 90%',
                toggleActions: 'play none none reverse',
              },
              opacity: 1,
              y: 0,
              duration: 1,
              ease: 'ease-in-out',
            }
          );
        });
      });
    }, 0);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);

      clearTimeout(timeout);
    };
  }, []);

  const addToRefs = (el, refArray) => {
    if (el && !refArray.current.includes(el)) {
      refArray.current.push(el);
    }
  };
  const getDescText = (desc) => {
    return desc.startsWith('company.') ? t(desc) : desc;
  };
  const getDescListText = (descList) => {
    return descList.startsWith('company.') ? t(descList) : descList;
  };

  return (
    <React.Fragment>
      {/* 루트별 공통 비주얼 헤더 */}
      <CompanyHead/>

      {/* 컨텐츠 */}
      <Wrap>
        <section className='history_sec'>
          <div className='page_tit'>
            <h4>{t('company.history.title')}</h4>
            <p>{t('company.history.sub')}</p>
          </div>
          <div className='year_wrap'>
            {/* 2022 ~ Now */}
            <div ref={el => addToRefs(el, ScrollWrapRef)} className='year_con reverse'>
              <div className='desc_sec'>
                <div className='desc_in' ref={el => addToRefs(el, ScrollItemRef)}>
                  {TOP_HISTORY.map((item, index) => (
                    <ul key={index} ref={el => addToRefs(el, FadeItemRef)} className='desc_list'>
                      <li>
                        <div className='year_in'>
                          <strong>{item.year}</strong>
                          <ul>
                            {item.months.map((month, mIndex) => (
                              <li key={mIndex}>
                                <div className='month_in'>
                                  <span>{month.month}</span>
                                  <div className='month_txt'>
                                    {month.descList ? (
                                      <>
                                        {month.descList.map((text, tIndex) => (
                                          <p className={month.isBlue ? 'blue' : ''} key={tIndex}>{getDescText(text)}</p>
                                        ))}
                                      </>
                                    ) : (
                                      <p className={month.isBlue ? 'blue' : ''}>{getDescText(month.desc)}</p>
                                    )}
                                    {month.images && (
                                      <div className='month_img'>
                                        {month.images.map((img, imgIndex) => (
                                          <HistoryImg key={imgIndex} src={img}/>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
              <div ref={el => addToRefs(el, PinRef)} className='img_sec s1'>
                <div className='img_desc'>
                  <span>{t('company.history.twentyTwo.img.first')}</span>
                  <div>
                    <strong>2022</strong>
                    <i/>
                    <strong>{t('company.history.twentyTwo.img.second')}</strong>
                  </div>
                </div>
              </div>
            </div>
            {/* 2013 ~ 2021 */}
            <div ref={el => addToRefs(el, ScrollWrapRef)} className='year_con'>
              <div ref={el => addToRefs(el, PinRef)} className='img_sec s2'>
                <div className='img_desc'>
                  <span>{t('company.history.twentyOne.img.first')}</span>
                  <div>
                    <strong>2013</strong>
                    <i/>
                    <strong>2021</strong>
                  </div>
                </div>
              </div>
              <div className='desc_sec right'>
                <div className='desc_in' ref={el => addToRefs(el, ScrollItemRef)}>
                  {MID_HISTORY.map((item, index) => (
                    <ul key={index} ref={el => addToRefs(el, FadeItemRef)} className='desc_list'>
                      <li>
                        <div className='year_in'>
                          <strong>{item.year}</strong>
                          <ul>
                            {item.months.map((month, mIndex) => (
                              <li key={mIndex}>
                                <div className='month_in'>
                                  <span>{month.month}</span>
                                  <div className='month_txt'>
                                    {month.descList ? (
                                      <>
                                        {month.descList.map((text, tIndex) => (
                                          <p className={month.isBlue ? 'blue' : ''} key={tIndex}>{getDescText(text)}</p>
                                        ))}
                                      </>
                                    ) : (
                                      <p className={month.isBlue ? 'blue' : ''}>{getDescText(month.desc)}</p>
                                    )}
                                    {month.image && (
                                      <div className='month_img'>
                                        <HistoryImg src={month.image}/>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
            {/* 2008 ~ 2012 */}
            <div ref={el => addToRefs(el, ScrollWrapRef)} className='year_con reverse'>
              <div className='desc_sec'>
                <div className='desc_in' ref={el => addToRefs(el, ScrollItemRef)}>
                  {BOT_HISTORY.map((item, index) => (
                    <ul key={index} ref={el => addToRefs(el, FadeItemRef)} className='desc_list'>
                      <li>
                        <div className='year_in'>
                          <strong>{item.year}</strong>
                          <ul>
                            {item.months.map((month, mIndex) => (
                              <li key={mIndex}>
                                <div className='month_in'>
                                  <span>{month.month}</span>
                                  <div className='month_txt'>
                                    {month.descList ? (
                                      <>
                                        {month.descList.map((text, tIndex) => (
                                          <p className={month.isBlue ? 'blue' : ''} key={tIndex}>{getDescText(text)}</p>
                                        ))}
                                      </>
                                    ) : (
                                      <p className={month.isBlue ? 'blue' : ''}>{getDescText(month.desc)}</p>
                                    )}
                                    {month.image && (
                                      <div className='month_img'>
                                        <HistoryImg src={month.image}/>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
              <div ref={el => addToRefs(el, PinRef)} className='img_sec s3'>
                <div className='img_desc'>
                  <span>{t('company.history.eight.img.first')}</span>
                  <div>
                    <strong>2008</strong>
                    <i/>
                    <strong>2012</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Wrap>
    </React.Fragment>
  );
};

const Wrap = styled.div`
  position: relative;
  padding: 140px 0 180px;

  // 페이지 타이틀
  & .page_tit {
    max-width: 1440px;
    margin: 0 auto;
    text-align: center;

    & h4 {
      font-weight: 800;
      font-size: var(--font-page-b);
      line-height: 1.2;
      color: var(--color-grey-100);
    }

    & p {
      margin-top: 18px;
      display: block;
      font-weight: 400;
      font-size: var(--font-page-s);
      line-height: 1.5;
      color: var(--color-grey-80);
      white-space: pre-line;
    }
  }

  // 연혁 Content
  & .year_wrap {
    margin-top: 80px;
    position: relative;

    & .year_con {
      position: relative;
      display: flex;

      & + .year_con {
        margin-top: 250px;
      }

      & .img_sec {
        position: relative;
        width: 50%;
        height: 100vh;

        &.s1 {
          background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/history_sec01.webp') no-repeat;
          background-size: cover;
        }

        &.s2 {
          background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/history_sec02.webp') no-repeat;
          background-size: cover;
        }

        &.s3 {
          background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/history_sec03.webp') no-repeat;
          background-size: cover;
        }

        & .img_desc {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;

          & span {
            font-weight: 600;
            font-size: var(--font-main-sub-tit);
            color: var(--color-white);
          }

          & > div {
            margin-top: 30px;
            display: flex;
            align-items: center;
            gap: 0 30px;

            & strong {
              font-weight: 800;
              font-size: var(--font-main-tit);
              color: var(--color-white);
            }

            & i {
              display: block;
              width: 85px;
              height: 2px;
              background: var(--color-white);
            }
          }
        }
      }

      & .desc_sec {
        width: 50%;
        padding: 0 60px;
        display: flex;
        justify-content: flex-end;

        &.right {
          justify-content: flex-start;
        }

        & .desc_in {
          width: 100%;
          position: relative;

          & .desc_list {
            position: relative;
            border-bottom: 1px solid var(--color-grey-20);

            & .year_in {
              display: flex;
              gap: 0 20px;
              padding: 60px 0;

              & strong {
                display: block;
                min-width: 85px;
                font-weight: 900;
                font-size: var(--font-history-tit);
                color: var(--color-grey-100);
              }

              & li + li {
                margin-top: 60px;
              }

              & .month_in {
                display: flex;
                gap: 0 20px;
                line-height: 34px;

                & span {
                  min-width: 30px;
                  display: block;
                  font-weight: 800;
                  font-size: var(--font-history-txt);
                  color: var(--primary-color);
                }

                & p {
                  font-weight: 400;
                  font-size: var(--font-history-txt);
                  color: var(--color-grey-90);

                  &.blue {
                    font-weight: 700;
                    color: var(--primary-color);
                  }
                }

                & .month_img {
                  display: flex;
                  gap: 0 16px;
                }

                & img {
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {

    padding: 70px 0 90px;

    & .year_wrap {

      & .year_con {
        position: relative;
        display: flex;
        flex-direction: column;

        &.reverse {
          flex-direction: column-reverse;
        }

        & + .year_con {
          margin-top: 70px;
        }

        & .img_sec {
          width: 100%;
          height: 50vh;
        }

        & .desc_sec {
          width: 100%;
          padding: 0 16px;

          & .desc_in {
            & .desc_list {
              & .year_in {
                display: flex;
                flex-direction: column;
                gap: 25px 0;
                padding: 40px 0 30px;

                & li + li {
                  margin-top: 20px;
                }

                & .month_in {
                  & .month_img {
                    gap: 0 10px;

                    & > img {
                      width: 33.33%;
                    }
                  }

                  & img {
                    width: 100%;
                  }
                }

              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 540px) {

    padding: 35px 0 45px;

    & .year_wrap {
      margin-top: 40px;

      & .year_con {
        & + .year_con {
          margin-top: 35px;
        }

        & .img_sec {
          height: 30vh;
        }

        & .desc_sec {
          & .desc_in {
            & .desc_list {
              & .year_in {
                padding: 20px 0 15px;

                & li + li {
                  margin-top: 12px;
                }

                & .month_in {
                  display: flex;
                  gap: 0 10px;
                  line-height: 25px;

                  & span {
                    min-width: 20px;
                  }
                }
              }
            }
          }
        }
      }

    }
  }
`;

const HistoryImg = styled.img.attrs({ alt: '연혁 이미지' })``;

export default CompanyHistory;