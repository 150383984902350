import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// BG 컴포넌트
import Dimmed from 'components/Common/Dimmed';

export default function SpiralModal({ item, onClose }) {
  const formatData = (text) => {
    return text.split('\n').map((str, index) => (
      <Fragment key={index}>
        {str}
        <br/>
      </Fragment>
    ));
  };

  return (
    <GasketModal onClick={onClose}>
      <div className='modal_inner'>
        <div className='modal_img'>
          <img src={item.image} alt={item.name}/>
        </div>
        <div className='modal_desc'>
          <div className='modal_txt'>
            <strong>{item.name}</strong>
            <span>{item.desc}</span>
            <p>{item.info}</p>
          </div>
          <ul className='modal_info'>
            {item.list && item.list.map((prop, propIndex) => (
              <li key={propIndex}>
                <strong>{prop.label}</strong>
                <span>{formatData(prop.value)}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Dimmed/>
    </GasketModal>
  )
}

SpiralModal.propTypes = {
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

const GasketModal = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .modal_inner {
    z-index: 3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    padding: 20px 12px;
    background: var(--color-white);

    .modal_img {
      position: relative;
      width: 75%;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }

    .modal_desc {
      margin-top: 20px;

      .modal_txt {
        strong {
          font-weight: 700;
          font-size: var(--font-card-tit);
          color: var(--color-grey-100);
        }

        span {
          margin-top: 10px;
          display: block;
          font-weight: 500;
          font-size: var(--font-nav-sub-tit);
          line-height: 1.2;
          color: var(--color-grey-60);
        }

        p {
          margin-top: 15px;
          display: block;
          font-weight: 400;
          font-size: var(--font-nav-sub-tit);
          line-height: 1.2;
          color: var(--color-grey-80);
        }

        br {
          display: none;
        }
      }

      .modal_info {
        margin-top: 20px;
        position: relative;
        border-top: 1px solid var(--color-grey-60);
        border-bottom: 1px solid var(--color-grey-60);

        li {
          width: 100%;
          padding: 12px;

          strong {
            font-weight: 600;
            font-size: var(--font-nav-sub-tit);
            line-height: 1.2;
            color: var(--color-grey-60);
            letter-spacing: 0.03rem;
          }

          span {
            margin-top: 5px;
            display: block;
            font-weight: 400;
            font-size: var(--font-nav-sub-tit);
            line-height: 1.5;
            color: var(--color-grey-90);
          }
        }

        li + li {
          border-top: 1px solid var(--color-grey-30);
        }
      }
    }
  }
`;