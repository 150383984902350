import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

/* swiper */
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const CompanyHead = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const swiperRef = useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const [activeLink, setActiveLink] = useState('');

  const setLink = (path) => {
    const links = {
      '/company/outline': t('header.company.outline'),
      '/company/ceo': t('header.company.ceo'),
      '/company/history': t('header.company.history'),
      '/company/business': t('header.company.business'),
      '/company/certificate': t('header.company.certificate'),
      '/company/global': t('header.company.globalBusiness'),
      '/company/way': t('header.company.wayToCome'),
    };

    setActiveLink(links[path] || '');
  };

  useEffect(() => {
    setLink(location.pathname);

    const swiper = swiperRef.current?.swiper;
    const index = navItems.findIndex(item => item.to === location.pathname);

    if (swiper && index !== -1) {
      swiper.slideTo(index, 0); // 0은 애니메이션 없이 바로 이동하는 옵션입니다.
    }
  }, [location.pathname]);

  useEffect(() => {
    setLink(location.pathname);
  }, [i18n.language]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navItems = [
    { to: '/company/outline', label: t('header.company.outline') },
    { to: '/company/ceo', label: t('header.company.ceo') },
    { to: '/company/history', label: t('header.company.history') },
    { to: '/company/business', label: t('header.company.business') },
    { to: '/company/certificate', label: t('header.company.certificate') },
    { to: '/company/way', label: t('header.company.wayToCome') },
  ];

  return (
    <React.Fragment>
      <HeadWrap>
        {/* 비주얼 & 타이틀 */}
        <div className='head_visual'>
          <h2>{activeLink}</h2>
        </div>
        {/* 서브 Nav */}
        <div className='head_sub_nav'>
          <div className='inner'>
            <Swiper
              className='sub_nav_list'
              slidesPerView={'auto'}
              ref={swiperRef}
            >
              {navItems.map((item, index) => (
                <SwiperSlide className='sub_nav_item' key={item.to}>
                  <NavLink
                    to={item.to}
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    {item.label}
                  </NavLink>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </HeadWrap>
    </React.Fragment>
  );
};

const HeadWrap = styled.div`
  position: relative;

  .test-btn {
    position: relative;
    width: 100%;
  }

  .test-btn button {
    display: block;
    padding: 0 16px;
    margin: 20px auto;
    height: 40px;
    line-height: 40px;
    border: 1px solid var(--color-grey-20);
    border-radius: 5px;
  }

  // 페이지 비주얼 헤더
  & .head_visual {
    position: relative;
    width: 100%;
    height: 450px;
    background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/layout/company_top.webp') no-repeat;
    background-size: cover;

    & h2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: calc(100% - 16px);
      font-weight: 800;
      font-size: var(--font-page-b);
      color: var(--color-white);
    }
  }

  // 페이지 서브 Nav
  & .head_sub_nav {
    position: relative;
    width: 100%;
    border-bottom: 1px solid var(--color-grey-20);

    & .inner {
      max-width: 1440px;
      margin: 0 auto;

      & .sub_nav_list {
        display: flex;
        align-items: center;

        & .sub_nav_item {
          flex: 1 1 0;
          text-align: center;

          & a {
            padding: 30px 0;
            display: block;
            font-weight: 400;
            font-size: var(--font-page-s);
            color: var(--color-grey-100);

            &.active {
              font-weight: 700;
              border-bottom: 3px solid var(--primary-color);
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {

    & .head_visual {
      height: 300px;
    }

    // Sub Nav
    & .head_sub_nav {
      & .inner {
        max-width: none;
        margin: 0;

        & .sub_nav_list {
          display: flex;
          align-items: center;

          & .sub_nav_item {
            flex: none;
            width: 27.5% !important;

            & a {
              padding: 18px 0;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {

    & .head_visual {
      height: 200px;
    }
  }
`;

export default CompanyHead;