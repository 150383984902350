import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import CompanyHead from 'components/Company/CompanyHead';

const CompanyWayToCome = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <React.Fragment>
      {/* 루트별 공통 비주얼 헤더 */}
      <CompanyHead/>

      {/* 컨텐츠 */}
      <Wrap>
        {/* 주요 사업 */}
        <section className='way_sec'>
          <div className='sec_inner'>
            <div className='page_tit'>
              <h4>{t('company.wayToCome.title.first')}</h4>
              <p>{t('company.wayToCome.title.second')}</p>
            </div>

            <div className='way-wrap'>
              <div className='map_con'>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25439.439172961673!2d127.09367619239198!3d37.154366393578265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b465e1e50fc1b%3A0x3f0b492e07a5d0de!2zKOyjvCnsnKDsl5DsiqTti7A!5e0!3m2!1sen!2sen!4v1713157543174!5m2!1sen!2sen'
                  width='100%'
                  height='550'
                  style={{ border: 'none' }}
                  allowFullScreen=''
                  loading='lazy'
                  referrerPolicy='no-referrer-when-downgrade'
                  title='ust map'
                />
              </div>
              <div className='map_desc'>
                <strong>{t('company.wayToCome.address')}</strong>
                <ul>
                  <li>
                    <div className='desc_in'>
                      <span>TEL</span>
                      <p>031-786-1027</p>
                    </div>
                  </li>
                  <li>
                    <div className='desc_in'>
                      <span>FAX</span>
                      <p>031-378-6694</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </Wrap>
    </React.Fragment>
  );
};

const Wrap = styled.div`
  position: relative;

  // 오시는길
  & .way_sec {
    padding: 140px 0 200px;

    .way-wrap {
      margin-top: 80px;
      position: relative;

      .map_desc {
        margin-top: 60px;

        strong {
          font-weight: 700;
          font-size: var(--font-card-tit);
          color: var(--color-grey-100);
        }

        ul {
          position: relative;
          width: 100%;
          margin-top: 30px;
          padding-top: 30px;
          border-top: 1px solid var(--color-grey-30);
          display: flex;
          align-items: center;
          gap: 0 160px;

          li .desc_in {
            display: flex;
            align-items: center;
            gap: 0 30px;

            span {
              font-weight: 700;
              font-size: var(--font-card-sub-tit);
              color: var(--color-grey-60);
            }

            p {
              font-weight: 700;
              font-size: var(--font-card-sub-tit);
              color: var(--color-grey-90);
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    & .way_sec {
      padding: 70px 0 90px;

      .way-wrap {
        margin-top: 60px;
        position: relative;

        .map_con iframe {
          height: 350px;
        }

        .map_desc {
          margin-top: 30px;

          strong {
            font-weight: 700;
            font-size: var(--font-card-tit);
            color: var(--color-grey-100);
          }

          ul {
            position: relative;
            width: 100%;
            margin-top: 15px;
            padding-top: 15px;
            border-top: 1px solid var(--color-grey-30);
            display: block;

            li + li {
              margin-top: 10px;
            }

            li .desc_in {
              display: flex;
              align-items: center;
              gap: 0 30px;

              span {
                font-weight: 700;
                font-size: var(--font-card-sub-tit);
                color: var(--color-grey-60);
              }

              p {
                font-weight: 700;
                font-size: var(--font-card-sub-tit);
                color: var(--color-grey-90);
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 540px) {
    & .way_sec {
      padding: 35px 0 45px;

      .way-wrap {
        margin-top: 30px;
        position: relative;

        .map_con iframe {
          height: 250px;
        }
      }
    }
  }
`;

export default CompanyWayToCome;