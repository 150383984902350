import { useTranslation } from 'react-i18next';
import useDidMountEffect from 'hooks/useDidMountEffect';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

export default function PadItem({ image, name, desc, info, list }) {
  const { t, i18n } = useTranslation();
  const FadeUpRef = useRef([]);

  const [isEng, setIsEng] = useState(false);

  const lang = {
    ko: false,
    en: true,
    cn: false
  };

  useEffect(() => {
    FadeUpRef.current.forEach((el) => {
      gsap.fromTo(
        el, {
          autoAlpha: 0,
          opacity: 0,
          y: 100,
        },
        {
          duration: 1,
          autoAlpha: 1,
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: el,
            start: 'top 80%',
            toggleActions: 'restart none none reverse',
          },
        }
      )
    });
  }, []);

  useDidMountEffect(() => {
    setIsEng(lang[i18n.language]);
  }, [i18n.language]);

  const getInfoText = (info) => {
    return info.startsWith('solution.') ? t(info) : info;
  };
  const getListText = (value) => {
    return value.startsWith('solution.') ? t(value) : value;
  };
  const formatData = (text) => {
    return text.split('\n').map((str, index) => (
      <Fragment key={index}>
        {str}
        <br/>
      </Fragment>
    ));
  };
  const addToRefs = (el, refArray) => {
    if (el && !refArray.current.includes(el)) {
      refArray.current.push(el);
    }
  };

  return (
    <li className='list_item' ref={el => addToRefs(el, FadeUpRef)}>
      <div className='item_in'>
        <div className='item_img'>
          <img src={image} alt={name}/>
        </div>
        <div className='item_desc'>
          <strong>{name}</strong>
          <span>{desc}</span>
          <p>{getInfoText(info)}</p>
          <ul className={isEng ? 'eng' : ''}>
            {list.map((prop, propIndex) =>
              <li key={propIndex}>
                <strong className='cate'>{prop.label}</strong>
                <span className='info'>{formatData(getListText(prop.value))}</span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </li>
  )
}