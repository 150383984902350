export const isEmpty = value => {
  return value === null || value === undefined || value === '' || value.toString().trim() === '';
};

export const isEmail = value => {
  const regex = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  return regex.test(value);
};

export const isPhone = value => {
  const regPhone = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;

  return regPhone.test(value);
};

export const onlyNumber = value => {
  let val = value || '';

  val = val === '' ? val : val.replace(/[^0-9]/g, '');

  return val;
};

export const moveUrl = url => {
  window.open(url);
};

window.moveUrl = moveUrl;

export const replaceHref = text => {
  const sHrefPosition = [];
  const eHrefPosition = [];

  let pos = text.indexOf('href="');

  while (pos > - 1) {
    sHrefPosition.push(pos);

    pos = text.indexOf('href="', pos + 1);
  }

  pos = text.indexOf('">');

  let _cnt  = 0;

  while (pos > - 1) {
    if (!isEmpty(sHrefPosition[_cnt]) && sHrefPosition[_cnt] < pos) {
      eHrefPosition.push(pos);

      _cnt ++;
    }

    pos = text.indexOf('">', pos + 1);
  }

  let content = text;

  for (let j = sHrefPosition.length - 1; j >= 0; j--) {
    const url = content.substring(sHrefPosition[j], eHrefPosition[j]).replaceAll('href="', '');

    content = `${content.substring(0, sHrefPosition[j])}onclick=moveUrl(\'${url}\')${content.substring(eHrefPosition[j] + 1, content.length)}`;
  }

  return content;
};