import React, { useEffect, useState } from 'react';
import useDidMountEffect from 'hooks/useDidMountEffect';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BoardTable = props => {
  const { t } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const [isPage, setIsPage] = useState(false);
  const [search, setSearch] = useState({
    offset: parseInt(localStorage.getItem('noticePage')) || 0,
    language: '',
  });
  const [isBtn, setIsBtn] = useState(0);

  useEffect(() => {
    if (props.searchData) {
      setSearch(props.searchData);
    }
  }, [props.searchData]);

  useEffect(() => {
    if (props.data.offset === 0) {
      setIsBtn(0);
    } else if (props.data.offset !== 0 && props.data.offset !== props.data.pageNum - 1) {
      setIsBtn(1);
    } else if (props.data.offset === props.data.pageNum - 1) {
      setIsBtn(2);
    }
  }, [props.data.offset]);

  useDidMountEffect(() => {
    if (isPage) {
      props.search(search);
    }
  }, [search]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const pageChange = offset => {
    if (props.data.offset !== offset) {
      localStorage.setItem('noticePage', offset);

      setIsPage(true);
      setSearch(() => {
        return {
          ...search,
          offset: offset,
        };
      });
    }
  };

  return (
    <>
      <div className='board_list'>
        <p className='board_cnt'>
          {t('support.board.page.first')} {props.data.count}{t('support.board.page.second')}
          [ {props.data.offset + 1}/{props.data.pageNum} {t('support.board.page.third')} ]
        </p>
        <table>
          <thead>
          <tr>
            <th>{t('support.board.table.first')}</th>
            <th>{t('support.board.table.second')}</th>
            <th>{t('support.board.table.third')}</th>
            <th>{t('support.board.table.fourth')}</th>
          </tr>
          </thead>
          <tbody>
          {props.data.list.map((item, index) => {
            if (item.boardType === 'N') {
              return (
                <tr key={index}>
                  <td>{props.data.count - index - (props.data.offset * 5)}</td>
                  <td className='left'>
                    <Link to={`/support/notice/detail?boardType=${item.boardType}&seq=${item.seq}`}>
                      {item.title}
                    </Link>
                  </td>
                  <td>{item.regUserId}</td>
                  <td>{item.viewsCnt}</td>
                </tr>
              )
            } else {
              return (
                <tr key={index}>
                  <td>{props.data.count - index - (props.data.offset * 5)}</td>
                  <td className='left'>
                    <Link to={`/support/reference/detail?boardType=${item.boardType}&seq=${item.seq}`}>
                      {item.title}
                    </Link>
                  </td>
                  <td>{item.regUserId}</td>
                  <td>{item.viewsCnt}</td>
                </tr>
              )
            }
          })}
          </tbody>
        </table>
        <div className='board_page'>
          {props.data.offset !== 0 && (
            <>
              <button onClick={() => pageChange(0)}>
                <BoardArrow src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/support/first_arrow.svg'/>
              </button>
              <button onClick={() => pageChange(props.data.offset - 1)}>
                <BoardArrow src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/support/prev_arrow.svg'/>
              </button>
            </>
          )}
          <ul>
            {new Array(3).fill(0).map((_, i) => i + props.data.offset - isBtn).map((item, idx) => {
              if (item === props.data.offset) {
                return <li key={idx}><span className='active' onClick={() => pageChange(item)}>{item + 1}</span></li>;
              } else if (item >= 0 && item < props.data.pageNum) {
                return <li key={idx}><span onClick={() => pageChange(item)}>{item + 1}</span></li>;
              }
            })}
          </ul>
          {props.data.offset !== props.data.pageNum - 1 && (
            <>
              <button onClick={() => pageChange(props.data.offset + 1)}>
                <BoardArrow src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/support/next_arrow.svg'/>
              </button>
              <button onClick={() => pageChange(props.data.pageNum - 1)}>
                <BoardArrow src='https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/support/last_arrow.svg'/>
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const BoardArrow = styled.img.attrs({ alt: '게시판 페이지네이션' })``;

export default BoardTable;