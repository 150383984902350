import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';
import useDidMountEffect from 'hooks/useDidMountEffect';

// JS
import * as Server from 'assets/js/Server';
import * as Common from 'assets/js/Common';

// 컴포넌트
import CompanyHead from 'components/Company/CompanyHead';

gsap.registerPlugin(ScrollTrigger);

const CompanyCertificate = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const [certificateIndex, setCertificateIndex] = useState(6);
  const [awardsIndex, setAwardsIndex] = useState(6);
  const [search, setSearch] = useState({
    language: '',
    isEng: false,
  });
  const [certificate, setCertificate] = useState({
    certificate: [],
    awards: [],
  });

  const lang = {
    ko: {
      language: 'KOR',
      isEng: false,
    },
    en: {
      language: 'ENG',
      isEng: true
    },
    cn: {
      language: 'CHN',
      isEng: false
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setSearch(lang[i18n.language]);
  }, []);

  useDidMountEffect(() => {
    setSearch(lang[i18n.language]);
  }, [i18n.language]);

  useDidMountEffect(() => {
    if (!Common.isEmpty(search.language)) {
      callTestimonialList();
    }
  }, [search]);

  const callTestimonialList = () => {
    Server.sendGet('ustKr/testimonial', {
      language: search.language,
      useYn: 'Y',
    }, getTestimonialList).then();
  };
  const getTestimonialList = res => {
    const item = {
      certificate: [],
      awards: [],
    };

    res['resultList'].forEach(v => {
      if (v.type === '1') item.certificate.push(v);
      else item.awards.push(v);
    });

    setCertificate(item);
  };
  const isCertificateIndex = () => {
    if (certificateIndex + 6 < certificate.certificate.length) {
      setCertificateIndex(certificateIndex + 6);
    } else {
      setCertificateIndex(certificate.certificate.length);
    }
  };
  const isAwardsIndex = () => {
    if (awardsIndex + 6 < certificate.awards.length) {
      setAwardsIndex(awardsIndex + 6);
    } else {
      setAwardsIndex(certificate.awards.length);
    }
  };
  const renderCertificateMobileButton = () => {
    return isMobile && window.innerWidth < 641 && certificateIndex < certificate.certificate.length ?
      <button onClick={isCertificateIndex}>MORE</button> : null;
  };
  const renderAwardsMobileButton = () => {
    return isMobile && window.innerWidth < 641 && awardsIndex < certificate.awards.length ?
      <button onClick={isAwardsIndex}>MORE</button> : null;
  };

  return (
    <React.Fragment>
      {/* 루트별 공통 비주얼 헤더 */}
      <CompanyHead/>

      {/* 컨텐츠 */}
      <Wrap>
        {/* 인증서 */}
        <section className='cert_sec'>
          <div className='sec_inner'>
            <div className='page_tit'>
              <h4>{t('company.certificate.title.first')}</h4>
            </div>
            <div className='cert_list con_list'>
              <ul className={search.isEng ? 'eng' : ''}>
                {certificate?.certificate?.map((item, index) => {
                  if (!isMobile) {
                    return (
                      <li key={index}>
                        <div className='list_img'>
                          <CertImg src={item['testimonialImg']}/>
                        </div>
                        <div className='list_desc'>
                          <strong>{item['testimonialName']}</strong>
                          <ul>
                            <li>
                              <div className='desc_in'>
                                <strong>{t('company.certificate.info.first')}</strong>
                                <span>{item['certification']}</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    )
                  } else if (isMobile && index < certificateIndex) {
                    return (
                      <li key={index}>
                        <div className='list_img'>
                          <CertImg src={item['testimonialImg']}/>
                        </div>
                        <div className='list_desc'>
                          <strong>{item['testimonialName']}</strong>
                          <ul>
                            <li>
                              <div className='desc_in'>
                                <strong>{t('company.certificate.info.first')}</strong>
                                <span>{item['certification']}</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    )
                  }
                })}
              </ul>
              {renderCertificateMobileButton()}
            </div>
          </div>
        </section>
        {/* 특허 배너 */}
        <section className='patent_sec'>
          <div className='sec_inner'>
            <div className='patent_desc'>
              <strong>{t('company.certificate.banner.first')}</strong>
              <p>{t('company.certificate.banner.second')}</p>
            </div>
          </div>
        </section>
        {/* 수상 내역 */}
        <section className='prize_sec'>
          <div className='sec_inner'>
            <div className='page_tit'>
              <h4>{t('company.certificate.title.second')}</h4>
            </div>
            <div className='prize_list con_list'>
              <ul className={search.isEng ? 'eng' : ''}>
                {certificate?.awards?.map((item, index) => {
                  if (!isMobile) {
                    return (
                      <li key={index}>
                        <div className='list_img'>
                          <CertImg src={item['testimonialImg']}/>
                        </div>
                        <div className='list_desc'>
                          <strong>{item['testimonialName']}</strong>
                          <ul>
                            <li>
                              <div className='desc_in'>
                                <strong>{t('company.certificate.info.first')}</strong>
                                <span>{item['certification']}</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    )
                  } else if (isMobile && index < awardsIndex) {
                    return (
                      <li key={index}>
                        <div className='list_img'>
                          <CertImg src={item['testimonialImg']}/>
                        </div>
                        <div className='list_desc'>
                          <strong>{item['testimonialName']}</strong>
                          <ul>
                            <li>
                              <div className='desc_in'>
                                <strong>{t('company.certificate.info.first')}</strong>
                                <span>{item['certification']}</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    )
                  }
                })}
              </ul>
              {renderAwardsMobileButton()}
            </div>
          </div>
        </section>
      </Wrap>
    </React.Fragment>
  );
};

const Wrap = styled.div`
  position: relative;

  & .cert_sec {
    position: relative;
    padding: 140px 0 130px;
  }

  & .prize_sec {
    position: relative;
    padding: 130px 0 180px;
  }

  & .patent_sec {
    position: relative;
    padding: 85px 0;
    background: url('https://ust-kr.s3.ap-northeast-2.amazonaws.com/assets/company/solution_bg.webp') no-repeat;
    background-size: 100% 100%;

    &::before {
      z-index: 1;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 114, 185, 0.3);
    }

    & .patent_desc {
      z-index: 2;
      position: relative;
      text-align: center;

      & strong {
        font-weight: 800;
        font-size: var(--font-page-b);
        color: var(--color-white);
      }

      & p {
        margin-top: 15px;
        display: block;
        font-weight: 400;
        font-size: var(--font-page-s);
        color: var(--color-white);
      }
    }
  }

  & .con_list {
    margin-top: 80px;

    & > ul {
      display: flex;
      flex-wrap: wrap;
      gap: 80px 40px;

      & > li {
        width: calc(25% - 30px);

        & .list_img {
          position: relative;

          & img {
            width: 100%;
          }
        }

        & .list_desc {
          margin-top: 30px;

          & > strong {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: normal;
            font-weight: 700;
            font-size: var(--font-nav-sub-tit);
            color: var(--color-grey-100);

          }

          & ul {
            margin-top: 18px;

            > li {
              & + li {
                margin-top: 10px;
              }

              & .desc_in {
                display: flex;
                align-items: flex-start;
                gap: 0 10px;

                & strong {
                  display: block;
                  font-weight: 700;
                  font-size: var(--font-page-tit);
                  color: var(--color-grey-80);
                }

                & span {
                  font-weight: 400;
                  font-size: var(--font-page-tit);
                  color: var(--color-grey-80);
                }
              }
            }
          }
        }
      }

      &.eng {
        .list_desc > strong {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          white-space: normal;

          &:hover {
            display: block;
          }
        }

        .list_desc .desc_in {
          display: block;
        }
      }
    }

    button {
      margin: 75px auto 0;
      display: block;
      height: 45px;
      line-height: 45px;
      padding: 0 90px;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: var(--font-btn-18);
      color: var(--color-grey-80);
      text-align: center;
      border: 1px solid var(--color-grey-50);
    }
  }

  @media only screen and (max-width: 1024px) {
    & .cert_sec {
      padding: 70px 0 65px;
    }

    & .prize_sec {
      padding: 65px 0 90px;
    }

    & .patent_sec {
      padding: 40px 0;

      .patent_desc {
        & p {
          margin-top: 10px;
        }
      }
    }

    & .con_list {
      margin-top: 35px;

      & > ul {
        display: flex;
        flex-wrap: wrap;
        gap: 40px 30px;

        & > li {
          width: calc(33.33% - 20px);

          & .list_desc {
            margin-top: 15px;

            & ul {
              margin-top: 10px;

              > li {
                & + li {
                  margin-top: 5px;
                }
              }
            }
          }
        }

        &.eng {
          .list_desc > strong {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: normal;

            &:hover {
              display: block;
            }
          }

          .list_desc .desc_in {
            display: block;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 640px) {
    & .con_list {
      & > ul {
        display: flex;
        flex-wrap: wrap;
        gap: 40px 20px;

        & > li {
          width: calc(50% - 10px);
        }
      }
    }
  }

  @media only screen and (max-width: 420px) {
    & .con_list {
      & > ul {
        display: flex;
        flex-wrap: wrap;
        gap: 40px 0;

        & > li {
          width: 100%;
        }
      }
    }
  }
`;

const CertImg = styled.img.attrs({ alt: '인증서' })``;

export default CompanyCertificate;