import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useDidMountEffect from 'hooks/useDidMountEffect';

// JS
import * as Server from 'assets/js/Server';
import * as Common from 'assets/js/Common';

import SupportHead from 'components/Support/SupportHead';

const NoticeDetail = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const [search, setSearch] = useState({
    language: '',
  });
  const [board, setBoard] = useState({
    seq: 0,
    title: '',
    content: '',
    language: '',
    useYn: '',
    impYn: '',
    boardType: '',
    fileName: '',
    fileUrl: '',
    viewsCnt: 0,
    regDt: '',
    regUserId: '',
    updateDt: '',
    updateUserId: '',
    beforeSeq: 0,
    beforeTitle: '',
    afterSeq: 0,
    afterTitle: '',
  });

  const lang = {
    ko: 'KOR',
    en: 'ENG',
    cn: 'CHN'
  };

  useEffect(() => {
    window.scrollTo(0, 400);

    setSearch({
      language: lang[i18n.language]
    });

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useDidMountEffect(() => {
    navigate('/support/notice/list');
  }, [i18n.language]);

  useDidMountEffect(() => {
    window.scrollTo(0, 400);

    viewsCntIncrease();
    callBoardDetail();
  }, [searchParams]);

  useDidMountEffect(() => {
    if (!Common.isEmpty(search.language)) {
      viewsCntIncrease();
      callBoardDetail();
    }
  }, [search]);

  const callBoardDetail = () => {
    Server.sendGet('ustKr/board/detail', {
      seq: searchParams?.get('seq'),
      boardType: searchParams?.get('boardType'),
      language: search.language,
    }, getBoardDetail).then();
  };
  const getBoardDetail = res => {
    res['resultObject'].content = res['resultObject'].content?.replaceAll('<a href=', '<a target="_blank" href=');
    res['resultObject'].content = res['resultObject'].content?.replaceAll('<oembed url="https://www.youtube.com/watch?v=', '<iframe src="https://www.youtube.com/embed/');
    res['resultObject'].content = Common.replaceHref(res['resultObject'].content);

    setBoard(res['resultObject']);
  };
  const viewsCntIncrease = () => {
    Server.sendPost('ustKr/board/viewCnt', {
      seq: searchParams?.get('seq')
    }, null).then();
  };
  const toDataURL = url => {
    return fetch(url, { method: 'GET' }).then((response) => {
      return response.blob();
    }).then((blob) => {
      return URL.createObjectURL(blob);
    });
  };
  const downloadFile = async (url, fileName) => {
    const a = document.createElement('a');
    a.href = await toDataURL(url);
    a.download = fileName ?? 'download';

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <React.Fragment>
      {/* 루트별 공통 비주얼 헤더 */}
      <SupportHead/>

      {/* 컨텐츠 */}
      <Wrap>
        {/* Consult */}
        <section className='board_sec'>
          <div className='sec_inner'>
            <div className='board_detail'>
              {/* 게시글 내용 */}
              <div className='board_con'>
                <h6>{board?.title}</h6>
                {/* 첨부파일 */}
                {!Common.isEmpty(board?.fileUrl) &&
                  <div className='file_desc'>
                    <a href={'#'} onClick={() => downloadFile(board?.fileUrl, board?.fileName)}>
                      <span>{board?.fileName}</span>
                    </a>
                  </div>
                }
                <div className='board_desc' dangerouslySetInnerHTML={{ __html: board?.content }}/>
              </div>
              {/* 이전글 / 다음글 */}
              <table>
                <tbody>
                {!Common.isEmpty(board?.afterSeq) &&
                  <tr>
                    <th>{t('support.board.detail.after')}</th>
                    <td>
                      <Link to={`/support/notice/detail?boardType=${board?.boardType}&seq=${board?.afterSeq}`}>
                        {board?.afterTitle}
                      </Link>
                    </td>
                  </tr>
                }
                {!Common.isEmpty(board?.beforeSeq) &&
                  <tr>
                    <th>{t('support.board.detail.before')}</th>
                    <td>
                      <Link to={`/support/notice/detail?boardType=${board?.boardType}&seq=${board?.beforeSeq}`}>
                        {board?.beforeTitle}
                      </Link>
                    </td>
                  </tr>
                }
                </tbody>
              </table>
              {/* 목록 버튼 */}
              <div className='list_btn'>
                <Link to='/support/notice/list'>{t('support.board.detail.list')}</Link>
              </div>
            </div>
          </div>
        </section>
      </Wrap>
    </React.Fragment>
  );
};

const Wrap = styled.div`
  position: relative;

  .board_sec {
    position: relative;
    padding: 140px 0 180px;

    .board_detail {
      position: relative;
      border-top: 3px solid var(--color-grey-100);

      & .board_con {
        & h6 {
          padding: 25px 50px;
          font-weight: 500;
          font-size: var(--font-nav-sub-tit);
          color: var(--color-grey-80);
        }

        & .file_desc {
          border-top: 1px solid var(--color-grey-40);
          padding: 25px 50px;
          font-weight: 500;
          font-size: var(--font-nav-sub-tit);
          color: var(--color-grey-80);
        }

        & a {
          color: var(--primary-color);
          cursor: pointer;
        }

        & .board_desc {
          border-top: 1px solid var(--color-grey-40);
          border-bottom: 1px solid var(--color-grey-40);
          padding: 50px 16px;

          & iframe {
            width: 776px;
            height: 500px;
          }
        }
      }

      & table {
        tr {
          border-bottom: 1px solid var(--color-grey-40);

          th {
            padding: 25px 0;
            width: 10%;
            font-weight: 500;
            font-size: var(--font-nav-sub-tit);
            color: var(--color-grey-80);
            text-align: center;
          }

          td {
            width: auto;
            padding: 25px 50px;
            font-weight: 500;
            font-size: var(--font-nav-sub-tit);
            color: var(--color-grey-80);
          }
        }
      }

      & .list_btn {
        margin-top: 80px;
        text-align: right;

        a {
          display: inline-block;
          width: 95px;
          height: 55px;
          line-height: 55px;
          border: 1px solid var(--primary-color);
          border-radius: 100px;
          background: var(--color-white);
          font-weight: 500;
          font-size: var(--font-btn-18);
          color: var(--primary-color);
          text-align: center;
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .board_sec {
      padding: 70px 0 90px;

      .board_detail {
        & .board_con {
          & h6 {
            padding: 15px 16px;
          }

          & .file_desc {
            padding: 15px 16px;
          }

          & .board_desc {
            padding: 25px 16px;

            & img {
              max-width: 100%;
              height: auto;
            }
          }
        }

        & table {
          tr {
            th {
              padding: 15px 0;
            }

            td {
              padding: 15px 25px;
            }
          }
        }

        & .list_btn {
          margin-top: 45px;

          a {
            width: 95px;
            height: 40px;
            line-height: 40px;
          }
        }
      }

    }
  }

  @media only screen and (max-width: 540px) {
    .board_sec {
      padding: 35px 0 45px;

      .board_detail {
        & .list_btn {
          margin-top: 25px;

          a {
            width: 95px;
            height: 40px;
            line-height: 40px;
          }
        }
      }
    }
  }
`;

export default NoticeDetail;