import { Fragment, useEffect, useRef } from 'react';
import gsap from 'gsap';

export default function SpiralItem({ image, name, desc, list }) {
  const FadeUpRef = useRef([]);

  const formatData = (text) => {
    return text.split('\n').map((str, index) => (
      <Fragment key={index}>
        {str}
        <br/>
      </Fragment>
    ));
  };

  useEffect(() => {
    FadeUpRef.current.forEach((el) => {
      gsap.fromTo(
        el, {
          autoAlpha: 0,
          opacity: 0,
          y: 100,
        },
        {
          duration: 1,
          autoAlpha: 1,
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: el,
            start: 'top 80%',
            toggleActions: 'restart none none reverse',
          },
        }
      )
    });
  }, []);

  const addToRefs = (el, refArray) => {
    if (el && !refArray.current.includes(el)) {
      refArray.current.push(el);
    }
  };

  return (
    <li className='list_item' ref={el => addToRefs(el, FadeUpRef)}>
      <div className='item_in'>
        <div className='item_img'>
          <img src={image} alt={name}/>
        </div>
        <div className='item_desc'>
          <strong>{name}</strong>
          <span>{desc}</span>
          <ul>
            {list.map((prop, propIndex) =>
              <li key={propIndex}>
                <strong className='cate'>{prop.label}</strong>
                <span className='info'>{formatData(prop.value)}</span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </li>
  );
}