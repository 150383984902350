import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import GlobalStyles from 'assets/js/GlobalStyles';

// 레이아웃
import MainLayout from 'layout/MainLayout';
import ContentLayout from 'layout/ContentLayout';

// 페이지
import MainHome from 'pages/MainPage';
import CompanyOutline from 'pages/Company/outline';
import CompanyHistory from 'pages/Company/history';
import CompanyCeo from 'pages/Company/ceo';
import CompanyBusiness from 'pages/Company/business';
import CompanyCertificate from 'pages/Company/certificate';
import CompanyWayToCome from 'pages/Company/waytocome';
import SolutionSpiral from 'pages/Solution/SpiralGasket';
import SolutionPad from 'pages/Solution/PadGasket';
import RecruitTalent from 'pages/Recruit/Talent';
import RecruitBenefit from 'pages/Recruit/Benefit';
import SupportConsult from 'pages/Support/consult';
import NoticeList from 'pages/Support/Notice/List';
import NoticeDetail from 'pages/Support/Notice/Detail';

function App() {
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes('/notice')) {
      localStorage.removeItem('noticePage');
    }
  }, [location]);

  useEffect(() => {
    window.onbeforeunload = function pushRefresh() {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <>
      <GlobalStyles/>
      <Routes>
        <Route element={<MainLayout/>}>
          <Route path='*' element={<MainHome/>}/>
        </Route>
        <Route element={<MainLayout/>}>
          <Route path='/' element={<MainHome/>}/>
        </Route>
        <Route element={<ContentLayout/>}>
          <Route path='/company/outline' element={<CompanyOutline/>}/>
          <Route path='/company/ceo' element={<CompanyCeo/>}/>
          <Route path='/company/history' element={<CompanyHistory/>}/>
          <Route path='/company/business' element={<CompanyBusiness/>}/>
          <Route path='/company/certificate' element={<CompanyCertificate/>}/>
          <Route path='/company/way' element={<CompanyWayToCome/>}/>
          <Route path='/solution/spiral' element={<SolutionSpiral/>}/>
          <Route path='/solution/pad' element={<SolutionPad/>}/>
          <Route path='/recruit/talent' element={<RecruitTalent/>}/>
          <Route path='/recruit/benefit' element={<RecruitBenefit/>}/>
          <Route path='/support/notice/list' element={<NoticeList/>}/>
          <Route path='/support/notice/detail' element={<NoticeDetail/>}/>
          <Route path='/support/consult' element={<SupportConsult/>}/>
        </Route>
      </Routes>
    </>
  );
}

export default App;