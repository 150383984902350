import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useDidMountEffect from 'hooks/useDidMountEffect';

// JS
import * as Server from 'assets/js/Server';
import * as Common from 'assets/js/Common';

import SupportHead from 'components/Support/SupportHead';
import BoardTable from 'components/Support/BoardTable';

const NoticeList = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const [search, setSearch] = useState({
    offset: parseInt(localStorage.getItem('noticePage')) || 0,
    language: '',
  });
  const [notice, setNotice] = useState({
    pageNum: 0,
    count: 0,
    offset: parseInt(localStorage.getItem('noticePage')) || 0,
    list: [],
    isData: true,
  });

  const lang = {
    ko: 'KOR',
    en: 'ENG',
    cn: 'CHN'
  };

  useEffect(() => {
    setSearch({
      offset: parseInt(localStorage.getItem('noticePage')) || 0,
      language: lang[i18n.language]
    });

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1025);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useDidMountEffect(() => {
    setSearch({
      offset: parseInt(localStorage.getItem('noticePage')) || 0,
      language: lang[i18n.language]
    });
  }, [i18n.language]);

  useDidMountEffect(() => {
    if (!Common.isEmpty(search.language)) {
      callNoticeListCount();
    }
  }, [search]);

  useDidMountEffect(() => {
    if (!notice.isData) {
      Server.sendGet('ustKr/board', {
        language: search.language,
        useYn: 'Y',
        boardType: 'N',
        limit: 5,
        offset: search.offset === 0 ? 0 : search.offset * 5,
      }, getNoticeList).then();
    }
  }, [notice]);

  const callNoticeListCount = () => {
    Server.sendGet('ustKr/board/count', {
      language: search.language,
      useYn: 'Y',
      boardType: 'N',
    }, getNoticeListCount).then();
  };
  const getNoticeListCount = res => {
    setNotice(() => {
      return {
        ...notice,
        pageNum: Math.ceil(res['resultCount'] / 5),
        count: res['resultCount'],
        offset: search.offset,
        isData: false,
      };
    });
  };
  const getNoticeList = res => {
    setNotice(() => {
      return {
        ...notice,
        list: res['resultList'],
        isData: true,
      };
    });
  };
  const onSearch = res => {
    setSearch(null);
    setSearch(res);
  };

  return (
    <React.Fragment>
      {/* 루트별 공통 비주얼 헤더 */}
      <SupportHead/>

      {/* 컨텐츠 */}
      <Wrap>
        {/* Consult */}
        <section className='board_sec'>
          <div className='sec_inner'>
            <div className='page_tit'>
              <h4>{t('support.notice.title')}</h4>
              <p>{t('support.notice.sub')}</p>
            </div>

            <BoardTable data={notice} searchData={search} search={onSearch}/>
          </div>
        </section>
      </Wrap>
    </React.Fragment>
  );
};

const Wrap = styled.div`
  position: relative;

  .board_sec {
    position: relative;
    padding: 140px 0 180px;

    .board_list {
      margin-top: 80px;
      position: relative;

      & p {
        font-weight: 500;
        font-size: var(--font-nav-sub-tit);
        color: var(--color-grey-50);
      }

      & table {
        margin-top: 30px;
        border-top: 3px solid var(--color-grey-100);

        & th {
          width: 10%;
          padding: 25px 0;
          font-weight: 700;
          font-size: var(--font-nav-tit);
          color: var(--color-grey-80);
          text-align: center;
          border-bottom: 1px solid var(--color-grey-40);

          &:nth-child(2) {
            width: 70%;
          }
        }

        & td {
          width: 10%;
          padding: 25px 0;
          font-weight: 500;
          font-size: var(--font-nav-sub-tit);
          color: var(--color-grey-80);
          text-align: center;
          border-bottom: 1px solid var(--color-grey-40);

          & a {
            font-weight: 500;
            font-size: var(--font-nav-sub-tit);
            color: var(--color-grey-80);
          }

          &.left {
            padding: 25px 50px;
            text-align: left;
          }

          &:nth-child(2) {
            width: 70%;
          }
        }
      }

      & .board_page {
        margin-top: 80px;
        display: flex;
        gap: 0 20px;
        justify-content: center;
        align-items: center;

        & ul {
          display: flex;
          align-items: center;
          gap: 0 20px;

          & li {
            cursor: pointer;

            & span {
              display: block;
              width: 30px;
              height: 30px;
              line-height: 30px;
              border-radius: 50%;
              font-weight: 500;
              font-size: var(--font-page-tit);
              color: var(--color-grey-80);
              text-align: center;

              &.active {
                font-weight: 700;
                color: var(--color-white);
                background: var(--primary-color);
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .board_sec {
      padding: 70px 0 90px;

      .board_list {
        margin-top: 40px;

        & table {
          margin-top: 16px;
          table-layout: fixed;

          & th {
            width: 20%;
            padding: 16px 0;

            &:nth-child(2) {
              width: 40%;
            }
          }

          & td {
            vertical-align: middle;
            width: 20%;
            padding: 16px 0;
            box-sizing: border-box;

            &.left {
              padding: 16px 20px;
              text-align: left;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            &:nth-child(2) {
              width: 40%;
            }
          }
        }

        & .board_page {
          margin-top: 40px;

          & ul {
            gap: 0 12px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 540px) {
    .board_sec {
      padding: 35px 0 45px;

      .board_list {
        margin-top: 20px;

        & .board_page {
          margin-top: 20px;
        }
      }
    }
  }
`;

export default NoticeList;